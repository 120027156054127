<template lang="pug">
v-app.root
  client-only
    SmartBanner(:dark="false" v-if="!forcedHidden")
  AppBar
  v-main
    nuxt.content
    SignInDialog(ref="signInDialog")
  AppFooter
</template>

<script>
import AppBar from '../components/AppBar.vue'
import AppFooter from '../components/AppFooter.vue'
import SmartBanner from '../components/SmartBanner.vue'
import SignInDialog from '../components/editor/SignInDialog.vue'

export default {
  components: {
    AppBar,
    AppFooter,
    SmartBanner,
    SignInDialog
  },
  data() {
    return {
      forcedHidden: false
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('ios') || urlParams.has('android')) {
      this.forcedHidden = true
    }
  },
  created() {
    this.$nuxt.$on('openDialog', (val) => {
      if (val) {
        this.openDialog(val)
      }
    })
  },
  methods: {
    openDialog(dialogType) {
      if (dialogType === 'signIn') {
        this.$refs.signInDialog.open()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  overflow: hidden;
  background-color: #13c4ac !important;
  ::selection {
    background: rgba(255, 255, 255, 0.25) !important;
  }
}
.content {
  min-height: 80vh;
}
</style>