<template lang="pug">
body
  nuxt
</template>

<script>
export default {
  head() {
    return {
      title: 'Online Markdown & Fountain WYSIWYG Editor'
    }
  },
  mounted() {
    if (window.$crisp) {
      window.$crisp.push(['do', 'chat:hide'])
    }
    if (window) {
      window.onfocus = () => {
        this.$auth.refreshIdTokenIfExpired()
      }
    }
  }
}
</script>

<style lang="scss">
.appLayout {
  overflow: hidden;
  overscroll-behavior-y: none;
  &::selection {
    background: rgba(0, 200, 171, 0.25);
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>