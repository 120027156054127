export const NotificationAction = {
  FILE_MODIFIED: 0,
  FILE_DELETED: 1,

  SYNC_ALL_START: 100,
  SYNC_ALL_END: 101,
  SYNC_START: 102,
  SYNC_END: 103,
  SYNC_ONGOING: 104
}

export const FileNotification = (src, linkedAccountId, parentFile, file, action) => {
  return {
    notificationType: 'FileNotification',
    src,
    linkedAccountId,
    parentFile,
    file,
    action
  }
}

export const SyncNotification = (action, linkedAccountId, errorCode) => {
  return {
    notificationType: 'SyncNotification',
    action,
    linkedAccountId,
    errorCode
  }
}