<template lang="pug">
v-footer.text-center(color="#272727" padless=true dark)
  v-container
    v-row
      v-col(cols="12")
        v-btn.mx-4.white--text(v-for='social in socials' :key="social.icon" icon='' :href="social.url" target="blank")
          v-icon(size='24px') {{ social.icon }}
    v-divider.my-4
    v-row
      v-col(v-for="footer in footers"  :key="footer.id" cols="12" lg="3" md="3")
        h4 {{footer.title}}
        v-list(color="#272727" dense=true)
          v-list-item(v-for="content in footer.content" :key="content.name" v-bind="linkAttrs(content)"  @click="content.action in actions ? actions[content.action]() : null")
            v-list-item-content
              v-list-item-title.text-caption {{content.name}}
  v-card-text.white--text
    .copyright
      | &copy; {{ new Date().getFullYear() }} &mdash;
      | JotterPad,
      = ' '
      a(href="https://2appstudio.com" target="_blank") Two App Studio Pte. Ltd.
    .copyright JotterPad is a trademark™ of Two App Studio Pte. Ltd.
  CookieDialog(ref="cookieDialog")
</template>

<script>
import CookieDialog from './CookieDialog.vue'

export default {
  components: {
    CookieDialog
  },
  data () {
    return {
      socials: [
        {icon: 'mdi-facebook', url: 'https://www.facebook.com/jotterpad.writer'},
        {icon: 'mdi-instagram', url: 'https://www.instagram.com/jotterpad.app'},
        {icon: 'mdi-twitter', url: 'https://twitter.com/2appstudio'},
        {icon: 'mdi-alpha-m-circle', url: 'https://medium.com/@jotterpad'}
      ],
      footers: [
        {
          id: 0,
          title: 'JotterPad',
          content: [
            {
              name: 'Help Desk',
              link: 'https://help.jotterpad.app',
              external: true
            },
            {
              name: 'Forum',
              link: 'https://forum.jotterpad.app',
              external: true
            },
            {
              name: 'Documentation',
              link: 'https://docs.jotterpad.app',
              external: true
            },
            {
              name: 'Templates',
              link: '/app/templates',
              external: false
            },
            {
              name: 'Plugins',
              link: '/app/plugins',
              external: false
            },
            // {
            //   name: 'Pro',
            //   link: '/pro',
            //   external: false
            // },
            {
              name: 'Blog',
              link: 'https://blog.jotterpad.app',
              external: true
            },
            {
              name: 'Company Website',
              link: 'https://2appstudio.com',
              external: true
            },
            {
              name: 'Privacy Policy',
              link: '/policy',
              external: false
            },
            {
              name: 'Terms Of Use (EULA)',
              link: '/termsGeneral',
              external: false
            },
            {
              name: 'Cookie Settings',
              action: 'cookie'
            }
          ]
        },
        {
          id: 1,
          title: 'Novel/Book Writing',
          content: [
            {
              name: 'What is a Novel',
              link: '/what-is-a-novel/'
            },
            {
              name: 'How to Write a Novel',
              link: '/how-to-write-a-novel/'
            },
            {
              name: 'How to Format a Novel',
              link: '/how-to-format-a-novel/'
            },
            {
              name: 'How to Write a Book',
              link: '/how-to-write-a-book/'
            },
            {
              name: 'How to Write an Essay',
              link: '/how-to-write-an-essay'
            },
            {
              name: 'How To Format a Book',
              link: '/how-to-format-a-book/'
            },
            {
              name: 'Novel Writing Software',
              link: '/writing-software-novel/'
            }
          ]
        },
        {
          id: 2,
          title: 'Screenwriting',
          content: [
            {
              name: 'What is a Screenplay',
              link: '/what-is-a-screenplay/'
            },
            {
              name: 'How to Write a Screenplay',
              link: '/how-to-write-a-screenplay/'
            },
            {
              name: 'How to Format a Screenplay',
              link: '/how-to-format-a-screenplay/'
            },
            {
              name: 'How to Write a Stage Play',
              link: '/how-to-write-a-stage-play/'
            },
            {
              name: 'Introducing Fountain Screenplay',
              link: '/what-is-fountain-screenplay/'
            },
            {
              name: 'Screenwriting Software',
              link: '/screenwriting-software/'
            },
            {
              name: 'Scriptwriting Templates',
              link: '/scriptwriting-templates/'
            }
          ]
        },
        {
          id: 3,
          title: 'Journal, Essay & Paper',
          content: [
            {
              name: 'What is an Academic Journal or Research Paper?',
              link: '/what-is-an-academic-journal-or-research-paper/'
            },
            {
              name: 'How To Write A Research Paper',
              link: '/how-to-write-a-research-paper/'
            },
            {
              name: 'How to Format a Research Paper',
              link: '/how-to-format-a-research-paper/'
            },
            {
              name: 'How to Format APA Paper',
              link: '/how-to-format-apa-paper/'
            },
            {
              name: 'How to Format MLA Paper',
              link: '/how-to-format-mla-paper/'
            },
            {
              name: 'How to Format Chicago Paper',
              link: '/how-to-format-chicago-paper/'
            },
            {
              name: 'What is an Essay',
              link: '/what-is-an-essay/'
            },
            {
              name: 'How To Write an Essay',
              link: '/how-to-write-an-essay'
            },
            {
              name: 'How To Format an Essay',
              link: '/how-to-format-an-essay'
            },
            {
              name: 'What is a College Essay',
              link: '/what-is-a-college-essay/'
            }
          ]
        }
      ],
      actions: {
        cookie: () => {
          console.log('cookie', this.$refs)
          this.$refs.cookieDialog.open()
        }
      }
    }
  },
  methods: {
    linkAttrs(content) {
      if (!content.action) {
        return {
          href: content.link,
          target: content.external ? '_blank' : ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footerLink {
  color: #ffffff80;
  text-decoration:none;
}

.copyright {
  font-weight: bold;

  a {
    text-decoration: none;
  }
}
</style>