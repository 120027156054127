module.exports = {
  async get(accessToken, endpoint, params, json) {
    const url = new URL(`https://www.googleapis.com/drive/v3/${endpoint}`)
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value)
    }
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        if (json) {
          const data = await response.json()
          return data
        } else {
          const data = await response.text()
          return data
        }
      } else {
        throw response.status
      }
    }
    return null
  },
  async request(method, accessToken, endpoint, params, body, json) {
    const url = new URL(`https://www.googleapis.com/drive/v3/${endpoint}`)
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value)
    }
    const response = await fetch(url.toString(), {
      method,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body
    })
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        if (json) {
          const data = await response.json()
          return data
        } else {
          const data = await response.text()
          return data
        }
      } else {
        throw response.status
      }
    }
    return null
  },
  async upload(method, accessToken, endpoint, params, body, json) {
    const url = new URL(`https://www.googleapis.com/upload/drive/v3/${endpoint}`)
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value)
    }
    const response = await fetch(url.toString(), {
      method,
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
      body
    })
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        if (json) {
          const data = await response.json()
          return data
        } else {
          const data = await response.text()
          return data
        }
      } else {
        throw response.status
      }
    }
    return null
  },
  async getRootFolder(accessToken) {
    const fields = 'id,name,version,mimeType,parents,modifiedTime'
    const data = await this.get(accessToken, 'files/root', {
      spaces: 'drive',
      fields
    }, true)
    if (data) {
      console.log(data)
      return data
    }
    return null
  },
  async getFileData(accessToken, googleId) {
    const fields = 'id,name,version,mimeType,parents'
    const data = await this.get(accessToken, `files/${googleId}`, {
      spaces: 'drive',
      fields
    }, true)
    if (data) {
      console.log(data)
      return data
    }
    return null
  },
  async listFiles(accessToken, googleParentId) {
    const fields = 'nextPageToken,files(id,name,version,mimeType,parents,modifiedTime)'
    const q = `(mimeType = 'application/vnd.google-apps.folder' or name contains '.md' or name contains '.txt' or name contains '.fountain' or name contains '.tex') and '${googleParentId || 'root'}' in parents and trashed = false`
    const data = await this.get(accessToken, 'files', {
      pageSize: 100,
      spaces: 'drive',
      fields,
      q
    }, true)
    let files
    if (data) {
      console.log(data)
      files = data.files
      let nextPageToken = data.nextPageToken
      while (nextPageToken) {
        const moreData = await this.listMoreFiles(accessToken, nextPageToken, fields, q)
        nextPageToken = moreData.nextPageToken
        files = files.concat(moreData.files)
      }
    }
    return files
  },
  async listMoreFiles(accessToken, nextPageToken, fields, q) {
    const data = await this.get(accessToken, 'files', {
      pageToken: nextPageToken,
      fields,
      q
    }, true)
    console.log(data)
    return data
  },
  async downloadFile(accessToken, googleId) {
    const data = await this.get(accessToken, `files/${googleId}`, {
      alt: 'media'
    }, false)
    return data
  },
  async uploadNewFile(accessToken, parentGoogleId, mimeType, name, content) {
    console.log('Upload new file: ', accessToken, parentGoogleId, mimeType, name, content)
    const fields = 'id,name,version,mimeType,parents,modifiedTime'
    const file = new Blob([content], { type: mimeType })
    const metadata = {
      name,
      mimeType,
      parents: [parentGoogleId]
    }
    const form = new FormData()
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }))
    form.append('file', file)
    const data = await this.upload('POST', accessToken, 'files', {
      uploadType: 'multipart',
      fields
    }, form, true)
    return data
  },
  async updateFile(accessToken, googleId, mimeType, name, content) {
    console.log('Update file: ', accessToken, googleId, mimeType, name, content)
    const fields = 'id,name,version,mimeType,parents,modifiedTime'
    const file = new Blob([content], { type: mimeType })
    const metadata = {
      name,
      mimeType
    }
    const form = new FormData()
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }))
    form.append('file', file)
    const data = await this.upload('PATCH', accessToken, `files/${googleId}`, {
      uploadType: 'multipart',
      fields
    }, form, true)
    return data
  },
  async uploadNewFolder(accessToken, parentGoogleId, name) {
    console.log('Upload new folder: ', accessToken, parentGoogleId, name)
    const fields = 'id,name,version,mimeType,parents,modifiedTime'
    const metadata = {
      name,
      mimeType: 'application/vnd.google-apps.folder',
      parents: [parentGoogleId]
    }
    const data = await this.request('POST', accessToken, 'files', {
      fields
    }, JSON.stringify(metadata), true)
    return data
  },
  async updateFileMetadata(accessToken, googleId, metadata) {
    console.log('Update file metadata: ', accessToken, googleId, metadata)
    const fields = 'id,name,version,mimeType,parents,modifiedTime'
    const data = await this.request('PATCH', accessToken, `files/${googleId}`, {
      fields
    }, JSON.stringify(metadata), true)
    return data
  },
  async getStartPageToken(accessToken) {
    const fields = 'startPageToken'
    const data = await this.get(accessToken, 'changes/startPageToken', {
      fields
    }, true)
    console.log(data)
    if (data && data.startPageToken) {
      return data.startPageToken
    }
    return null
  },
  async listChanges(accessToken, pageToken) {
    const fields = '*'
    const data = await this.get(accessToken, 'changes', {
      pageToken,
      pageSize: 50,
      spaces: 'drive',
      includeRemoved: true,
      fields
    }, true)
    let changes
    let newStartPageToken = null
    if (data) {
      console.log(data)
      changes = data.changes
      if (data.newStartPageToken) {
        newStartPageToken = data.newStartPageToken
      }
      let nextPageToken = data.nextPageToken
      while (nextPageToken) {
        const moreData = await this.listMoreChanges(accessToken, nextPageToken, fields)
        nextPageToken = moreData.nextPageToken
        changes = changes.concat(moreData.changes)
        if (moreData.newStartPageToken) {
          newStartPageToken = moreData.newStartPageToken
        }
      }
    }
    return {changes, newStartPageToken}
  },
  async listMoreChanges(accessToken, nextPageToken, fields) {
    const data = await this.get(accessToken, 'changes', {
      pageToken: nextPageToken,
      fields
    }, true)
    console.log(data)
    return data
  }
}