import Dexie from 'dexie'

const drive = new Dexie('Drive')
// synced: -1: not downloaded, 0: not synced, 1: synced
// kind: 'folder', 'file'
// {account: 'abc@gmail.com', name: 'test.md', googleId: 'abcdefg', dateModified: new Date(), synced: 0, kind: 'folder', version: 'a', parentGoogleId: ''}
// properties: exploredRoot: Bool, startPageToken: String
drive.version(1).stores({
  files: '++id,[id+linkedAccountId],[id+linkedAccountId+kind],[linkedAccountId+googleId],[linkedAccountId+name],[id+name],[linkedAccountId+synced],linkedAccountId,googleId,name,dateModified,synced,kind,version,content',
  trashes: '++id,[linkedAccountId+googleId],linkedAccountId,googleId',
  fileFolderRels: '++id,[linkedAccountId+parentId],[linkedAccountId+fileId],[linkedAccountId+parentId+fileId],linkedAccountId,fileId,parentId',
  properties: '[linkedAccountId+key],linkedAccountId,key,value'
})

const dropbox = new Dexie('Dropbox')
dropbox.version(1).stores({
  files: '++id,[linkedAccountId+parentId+nameLower],[linkedAccountId+synced],[linkedAccountId+parentId],[linkedAccountId+id],linkedAccountId,parentId,nameLower,name,dropboxId,dateModified,synced,kind,revision,content',
  trashes: '++id,linkedAccountId,dropboxId,pathLower',
  properties: '[linkedAccountId+key],linkedAccountId,key,value'
})

const onedrive = new Dexie('OneDrive')
onedrive.version(1).stores({
  files: '++id,[linkedAccountId+parentId+nameLower],[linkedAccountId+parentId+kind],[linkedAccountId+parentId],[linkedAccountId+synced],[linkedAccountId+onedriveId],linkedAccountId,name,nameLower,onedriveId,parentId,dateModified,synced,kind,eTag,content',
  trashes: '++id,[linkedAccountId+onedriveId],linkedAccountId, onedriveId',
  properties: '[linkedAccountId+key],linkedAccountId,key,value'
})

export default (context, inject) => {
  inject('dexie', {
    drive,
    dropbox,
    onedrive
  })
}