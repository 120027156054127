<template lang="pug">
v-app.root
  client-only
    SmartBanner(:dark="false" v-if="!forcedHidden")
  AppBar
  v-main
    nuxt.content
  AppFooter
</template>

<script>
import AppBar from '../components/AppBar.vue'
import AppFooter from '../components/AppFooter.vue'
import SmartBanner from '../components/SmartBanner.vue'

export default {
  components: {
    AppBar,
    AppFooter,
    SmartBanner
  },
  data() {
    return {
      forcedHidden: false
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('ios') || urlParams.has('android')) {
      this.forcedHidden = true
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
  overflow: hidden;
  background-color: #13c4ac;
  ::selection {
    background: rgba(255, 255, 255, 0.25) !important;
  }
}
.content {
  min-height: 80vh;
}
</style>