<template lang="pug">
  v-app.app
    AppBar(colored=true)
    v-main
      nuxt
</template>

<script>
import AppBar from '../components/AppBar.vue'

export default {
  components: {
    AppBar
  },
  mounted() {
    this.$vuetify.theme.dark = true
  }
}
</script>

<style lang="scss">
</style>