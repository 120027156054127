import sha256 from 'crypto-js/sha256'

import * as Drive from '../helper/io/drive.js'
import * as RecentItemsHelper from '../helper/RecentItemsHelper.js'
import { makeid } from '../helper/GeneralHelper.js'

import {auth, GoogleProvider, AppleProvider, firestore} from '@/services/firebase.js'

export const state = () => ({
  user: null,
  userIdToken: null,
  userIdTokenStoredTime: null,
  userClouds: [],
  fetchedUserClouds: false,
  userIsPaid: false,
  userPurchase: null,
  userIsAdmin: false,
  miniDrawer: true,
  copiedItem: null
})

export const getters = {
  activeUser: (state, getters) => {
    return state.user
  },
  activeUserIdToken: (state, getters) => {
    return state.userIdToken
  },
  activeUserIdTokenStoredTime: (state, getters) => {
    return state.userIdTokenStoredTime
  },
  activeUserClouds: (state, getters) => {
    return state.userClouds
  },
  activeUserIsPaid: (state, getters) => {
    return state.userIsPaid
  },
  activeUserPurchase: (state, getters) => {
    return state.userPurchase
  },
  activeUserIsAdmin: (state, getters) => {
    return state.userIsAdmin
  },
  activeMiniDrawer: (state, getters) => {
    return state.miniDrawer
  },
  activeCopiedItem: (state, getters) => {
    return state.copiedItem
  }
}

export const mutations = {
  setUser(state, payload) {
    state.user = JSON.parse(JSON.stringify(payload))
  },
  setUserIdToken(state, payload) {
    state.userIdToken = payload
  },
  setUserIdTokenStoredTime(state, payload) {
    state.userIdTokenStoredTime = payload
  },
  setUserClouds(state, payload) {
    state.userClouds = payload
  },
  clearUserClouds(state, payload) {
    state.userClouds = []
  },
  fetchedUserClouds(state, payload) {
    state.fetchedUserClouds = payload
  },
  setUserIsPaid(state, payload) {
    state.userIsPaid = payload
  },
  setUserPurchase(state, payload) {
    state.userPurchase = payload
  },
  setUserIsAdmin(state, payload) {
    state.userIsAdmin = payload
  },
  setMiniDrawer(state, payload) {
    state.miniDrawer = payload
  },
  setCopiedItem(state, payload) {
    state.copiedItem = payload
  }
}

export const actions = {
  autoSignIn({commit}, payload) {
    commit('setUser', payload)
  },
  async signInWithGoogle({commit}) {
    try {
      const result = await auth.signInWithPopup(GoogleProvider)
      if (result && result.user) {
        const idToken = await result.user.getIdToken()
        commit('setUser', result.user)
        commit('setUserIdToken', idToken)
        this.$axios.setHeader('Authorization', `Bearer ${idToken}`)
        return true
      }
    } catch (err) {
      console.log(err)
    }
    return false
  },
  signInWithGoogleRedirect({commit}) {
    auth.signInWithRedirect(GoogleProvider)
  },
  async signInWithApple({commit}) {
    try {
      const result = await auth.signInWithPopup(AppleProvider)
      if (result && result.user) {
        const idToken = await result.user.getIdToken()
        commit('setUser', result.user)
        commit('setUserIdToken', idToken)
        this.$axios.setHeader('Authorization', `Bearer ${idToken}`)
        return true
      }
    } catch (err) {
      console.log(err)
    }
    return false
  },
  signInWithAppleRedirect({commit}) {
    auth.signInWithRedirect(AppleProvider)
  },
  async signOut({commit}) {
    try {
      await auth.signOut()
      commit('setUser', null)
      commit('setUserIdToken', '')
      this.$axios.setHeader('Authorization', '')
      await Drive.unlinkAccount(this.$dexie)
      commit('clearUserClouds')
      window.localStorage.setItem('jp_clouds', [])
      commit('fetchedUserClouds', false)
      commit('setUserIsPaid', false)
      commit('setUserPurchase', null)
      commit('setUserIsAdmin', false)
      RecentItemsHelper.deleteNonLocalRecentItems()
    } catch (err) {
      console.log(err)
    }
  },
  async fetchUserClouds({commit, state}) {
    if (state.user && state.user.uid && !state.fetchedUserClouds) {
      let clouds = []
      let linkAccounts = []
      let errorFetch = false

      try {
        const res = await this.$axios.post(`${process.env.functionsBaseUrl}/oauth/all/linkedAccounts`)
        if (res && res.data && res.data.linkedAccounts) {
          linkAccounts = res.data.linkedAccounts
        }
      } catch (err) {
        console.log('Store: Unable to fetch clouds', err)
        errorFetch = true
      }

      for (const linkedAccount of linkAccounts) {
        clouds.push({
          userId: linkedAccount.userId,
          linkedAccountId: linkedAccount.linkedAccountId,
          displayName: linkedAccount.displayName,
          emailLower: linkedAccount.emailLower,
          id: linkedAccount.id,
          src: linkedAccount.src,
          updatedAt: new Date(linkedAccount.updatedAt)
        })
      }

      if (errorFetch && window.localStorage.getItem('jp_clouds')) {
        const cachedClouds = JSON.parse(window.localStorage.getItem('jp_clouds'))
        if (cachedClouds && cachedClouds.length) {
          clouds = cachedClouds
        }
      }

      clouds.sort((cloud1, cloud2) => {
        return `${cloud1.src}${cloud1.emailLower}`.localeCompare(`${cloud2.src}${cloud2.emailLower}`, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      })
      window.localStorage.setItem('jp_clouds', JSON.stringify(clouds))
      commit('fetchedUserClouds', true)
      commit('setUserClouds', clouds)
    }
  },
  addUserCloud({commit, state}, linkedAccount) {
    const clouds = state.userClouds.slice()
    if (linkedAccount && (typeof linkedAccount === 'object')) {
      const exist = !!clouds.find(cloud => cloud.linkedAccountId === linkedAccount.linkedAccountId)
      if (exist) {
        return
      }
      clouds.push({
        userId: linkedAccount.userId,
        linkedAccountId: linkedAccount.linkedAccountId,
        displayName: linkedAccount.displayName,
        emailLower: linkedAccount.emailLower,
        id: linkedAccount.id,
        src: linkedAccount.src,
        updatedAt: new Date(linkedAccount.updatedAt)
      })
      clouds.sort((cloud1, cloud2) => {
        return `${cloud1.src}${cloud1.emailLower}`.localeCompare(`${cloud2.src}${cloud2.emailLower}`, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      })
      window.localStorage.setItem('jp_clouds', JSON.stringify(clouds))
      commit('setUserClouds', clouds)
    }
  },
  removeUserCloud({commit, dispatch, state}) {
    if (state.user && state.user.uid) {
      commit('fetchedUserClouds', false)
      dispatch('fetchUserClouds')
    }
  },
  async checkUserIsPaid({commit}) {
    try {
      const requestCode = makeid(8)
      const res = await this.$axios.post(`${process.env.billingBaseUrl}/all/status`, {
        requestCode
      })
      if (sha256(requestCode).toString() === res.data.responseCode && ['year', 'month', 'lifetime'].includes(res.data.data.newSkuId)) {
        commit('setUserIsPaid', true)
        commit('setUserPurchase', res.data.data)
      } else {
        commit('setUserIsPaid', false)
        commit('setUserPurchase', null)
      }
    } catch (err) {
      commit('setUserIsPaid', false)
      commit('setUserPurchase', null)
    }
    // if (process.env.NODE_ENV !== 'production') {
    //   commit('setUserIsPaid', true)
    //   commit('setUserPurchase', { newSkuId: 'lifetime' })
    // }
  },
  async checkUserIsBillingAdmin({commit}) {
    try {
      const requestCode = makeid(8)
      const res = await this.$axios.post(`${process.env.billingBaseUrl}/admin/me`, {
        requestCode
      })
      if (sha256(requestCode).toString() === res.data.responseCode && res.data.data.adminLevel > 0) {
        commit('setUserIsAdmin', true)
      } else {
        commit('setUserIsAdmin', false)
      }
    } catch (err) {
      commit('setUserIsAdmin', false)
    }
  },
  toggleMiniDrawer({commit, state}) {
    commit('setMiniDrawer', !state.miniDrawer)
  },
  copyItem({commit}, copiedItem) {
    commit('setCopiedItem', copiedItem)
  }
}
