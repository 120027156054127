<template lang="pug">
  v-dialog(v-model="value" max-width="480px" persistent)
    v-card
      v-card-title.headline {{ $t('profile.cookieSettings') }}
      v-card-text.pt-0.pb-0
        v-container
          .text-caption.text-center.mt-3 When you visit any of our websites, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and manage your preferences. Please note, blocking some types of cookies may impact your experience of the site and the services we are able to offer.
          v-divider.mt-4
          v-list(dense)
            v-list-item
              v-list-item-content
                v-list-item-title Strictly Necessary
              v-list-item-action
                v-switch(v-model="level0" color="primary" inset :disabled="true")
            v-list-item
              v-list-item-content
                v-list-item-title Functional Cookies
              v-list-item-action
                v-switch(v-model="level1" color="primary" inset)
            v-list-item
              v-list-item-content
                v-list-item-title Targeting Cookies
              v-list-item-action
                v-switch(v-model="level2" color="primary" inset)
        v-card-actions.card-action
          v-spacer
          v-btn.text-capitalize(text @click='close()') {{ $t('button.close') }}
</template>

<script>
const Cookies = require('@/helper/CookiesHelper')

export default {
  data() {
    return {
      value: false,
      level0: true,
      level1: Cookies.getLevel() >= 1,
      level2: Cookies.getLevel() >= 2
    }
  },
  watch: {
    level1(newVal) {
      if (newVal) {
        Cookies.setLevel(1)
      } else {
        Cookies.setLevel(0)
        this.level2 = false
      }
    },
    level2(newVal) {
      if (newVal) {
        Cookies.setLevel(2)
        this.level1 = true
      } else {
        Cookies.setLevel(1)
      }
    }
  },
  methods: {
    open() {
      this.value = true
    },
    close() {
      this.value = false
    }
  }
}
</script>

<style scoped>
.headline {
  font-family: 'Ideal Sans A', 'Ideal Sans B', sans-serif !important;
  font-weight: bold !important;
}
.card-action {
  padding: 10px 24px 16px;
}
.locked {
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>