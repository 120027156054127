<template lang="pug">
  v-app.app
    AppBar(colored=true)
    v-main
      nuxt
    AppFooter
</template>

<script>
import AppBar from '../components/AppBar.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
  components: {
    AppBar,
    AppFooter
  },
  mounted() {
    this.$vuetify.theme.dark = false
  }
}
</script>