import { render, staticRenderFns } from "./SignInDialog.vue?vue&type=template&id=e4839996&scoped=true&lang=pug"
import script from "./SignInDialog.vue?vue&type=script&lang=js"
export * from "./SignInDialog.vue?vue&type=script&lang=js"
import style0 from "./SignInDialog.vue?vue&type=style&index=0&id=e4839996&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4839996",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VBtn,VCard,VCardText,VCardTitle,VDialog,VIcon,VImg})
