import moment from 'moment'

import * as GeneralHelper from '../GeneralHelper.js'
import * as SupportedFileHelper from '../SupportedFileHelper.js'
import { NotificationAction, FileNotification } from '../NotificationHelper'
import { SyncError } from '../Error'

export function getParentPathFromFullPath(fullPath) {
  const paths = fullPath.split('/')
  paths.pop()
  return paths.join('/')
}

export function getNameFromFullPath(fullPath) {
  const paths = fullPath.split('/')
  return paths.pop()
}

export function normalizePath(fullPath, lowercase) {
  if (fullPath && !fullPath.startsWith('/')) {
    return lowercase ? `/${fullPath}`.toLowerCase() : `/${fullPath}`
  }
  return lowercase ? fullPath.toLowerCase() : fullPath
}

export async function calculateBreadcrumbs($dexie, linkedAccountId, fullPath) {
  const breadcrumbs = []
  fullPath = (fullPath.startsWith('/')) ? fullPath.slice(1) : fullPath
  const paths = fullPath.length ? fullPath.split('/') : []
  while (paths.length > 0) {
    const id = await getFileIdByPathTokens($dexie, linkedAccountId, paths)
    const name = paths.pop()
    breadcrumbs.unshift({name, id})
  }
  return breadcrumbs
}

async function checkFileExists($dexie, linkedAccountId, parentId, name) {
  try {
    const count = await $dexie.dropbox.files.where({parentId, linkedAccountId, nameLower: name.toLowerCase()}).count()
    return count > 0
  } catch (err) {
    console.log(`Dexie.dropbox error! checkFileExists err: ${err}`)
    return false
  }
}

async function checkDuplicates($dexie, linkedAccountId, parentId, newName, suffix) {
  let name = `${newName}${suffix}`
  let exist = await checkFileExists($dexie, linkedAccountId, parentId, name)
  let number = 1
  let nameWithouthSuffix = newName
  while (exist) {
    nameWithouthSuffix = `${newName}-${number++}`
    name = `${nameWithouthSuffix}${suffix}`
    exist = await checkFileExists($dexie, linkedAccountId, parentId, name)
  }
  return nameWithouthSuffix
}

export async function getFullPathFromId($dexie, linkedAccountId, id) {
  const pathTokens = []
  if (id === -1) {
    return ''
  }
  let currentId = id
  do {
    const file = await $dexie.dropbox.files.get(currentId)
    if (file) {
      pathTokens.unshift(file.name)
      currentId = file.parentId
    } else {
      throw new Error('Error while reading full path from id')
    }
  } while (currentId !== -1)
  return normalizePath(pathTokens.join('/'), true)
}

export async function readCurrentDirectoryFiles($dexie, linkedAccountId, parentId) {
  console.log($dexie, linkedAccountId, parentId)
  const items = []

  parentId = parentId || ''

  try {
    await $dexie.dropbox.files.where({linkedAccountId, parentId}).each((file) => {
      let type
      if (file.kind === 'folder') {
        type = 'folder'
      } else {
        switch (GeneralHelper.getExtensionOnly(file.name)) {
          case SupportedFileHelper.DocumentFileFormat.MARKDOWN:
            type = SupportedFileHelper.DocumentFileFormat.MARKDOWN
            break
          case SupportedFileHelper.DocumentFileFormat.FOUNTAIN:
            type = SupportedFileHelper.DocumentFileFormat.FOUNTAIN
            break
          case SupportedFileHelper.DocumentFileFormat.LATEX:
            type = SupportedFileHelper.DocumentFileFormat.LATEX
            break
          case SupportedFileHelper.DocumentFileFormat.TEXT:
          default:
            type = SupportedFileHelper.DocumentFileFormat.TEXT
            break
        }
      }
      items.push({
        id: file.id,
        type,
        name: file.name,
        lastModified: file.dateModified.getTime() !== 0 ? moment(file.dateModified).valueOf() : '-',
        synced: file.synced
      })
    })
  } catch (err) {
    console.log(`Dexie.dropbox error! readCurrentDirectoryFiles err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_READ_FOLDER
    }
  }
  return {
    tableItems: items
  }
}

export async function getFile($dexie, linkedAccountId, parentId, nameLower) {
  try {
    const files = await $dexie.dropbox.files.where({linkedAccountId, parentId, nameLower}).toArray()
    return files.length ? files[0] : false
  } catch (err) {
    console.log(`Dexie.dropbox error! getFile err: ${err}`)
    return false
  }
}

export async function getFileFromId($dexie, id) {
  try {
    const file = await $dexie.dropbox.files.get(id)
    return (file && file.kind === 'file') ? file : false
  } catch (err) {
    console.log(`Dexie.dropbox error! getFileFromId err: ${err}`)
    return false
  }
}

export async function getFolderFromId($dexie, id) {
  try {
    const file = await $dexie.dropbox.files.get(id)
    return (file && file.kind === 'folder') ? file : false
  } catch (err) {
    console.log(`Dexie.dropbox error! getFolderFromId err: ${err}`)
    return false
  }
}

export async function getFileIdByPathTokens($dexie, linkedAccountId, pathTokens) {
  if (!pathTokens) {
    return -1
  }
  const pathLowerTokens = pathTokens.filter(t => t.length).map(t => decodeURIComponent(t.toLowerCase()))
  if (pathLowerTokens.length === 0) {
    return -1
  }
  try {
    let parentId = -1
    for (const pathLowerToken of pathLowerTokens) {
      const files = await $dexie.dropbox.files.where({linkedAccountId, parentId, nameLower: pathLowerToken}).toArray()
      if (files.length) {
        const file = files[0]
        parentId = file.id
      } else {
        return false
      }
    }
    return parentId
  } catch (err) {
    console.log(`Dexie.dropbox error! getFile err: ${err}`)
    return false
  }
}

export async function saveFile($dexie, linkedAccountId, parentId, savedFileId, oldFileName, newFileName, documentType, content) {
  console.log($dexie, linkedAccountId, parentId, savedFileId, newFileName, documentType, content)
  const extension = SupportedFileHelper.getFileFormat(documentType)
  let sanitizedNewFileName = GeneralHelper.sanitizedName(newFileName, true)
  if (oldFileName !== sanitizedNewFileName) {
    sanitizedNewFileName = await checkDuplicates($dexie, linkedAccountId, parentId, sanitizedNewFileName, `.${extension}`)
  }
  const newDate = new Date()
  if (savedFileId !== null) {
    const file = await $dexie.dropbox.files.get(savedFileId)
    if (!file) {
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_FILE
      }
    }
    try {
      await $dexie.dropbox.files.update(savedFileId, {
        name: `${sanitizedNewFileName}.${extension}`,
        nameLower: `${sanitizedNewFileName}.${extension}`.toLowerCase(),
        dateModified: newDate,
        synced: 0,
        content,
        revision: ''
      })
    } catch (err) {
      console.log(`Dexie.dropbox error! saveFile err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
  } else {
    try {
      savedFileId = await $dexie.dropbox.files.add({
        linkedAccountId,
        dropboxId: '',
        name: `${sanitizedNewFileName}.${extension}`,
        nameLower: `${sanitizedNewFileName}.${extension}`.toLowerCase(),
        parentId,
        dateModified: newDate,
        revision: '',
        synced: 0,
        kind: 'file',
        content
      })
    } catch (err) {
      console.log(`Dexie.dropbox error! renameFromEditor err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
  }
  return {
    savedFileId,
    oldFileName: sanitizedNewFileName,
    newFileName: sanitizedNewFileName,
    extension
  }
}

export async function renameFromEditor($dexie, linkedAccountId, parentId, savedFileId, oldFileName, newFileName, documentType) {
  console.log($dexie, savedFileId, oldFileName, newFileName, documentType)
  const extension = SupportedFileHelper.getFileFormat(documentType)
  let sanitizedNewFileName = GeneralHelper.sanitizedName(newFileName, true)
  if (oldFileName !== sanitizedNewFileName) {
    sanitizedNewFileName = await checkDuplicates($dexie, linkedAccountId, parentId, sanitizedNewFileName, `.${extension}`)
  }
  if (savedFileId !== null) {
    try {
      const file = await $dexie.dropbox.files.get(savedFileId)
      if (!file) {
        return {
          error: GeneralHelper.ErrorTypes.EDITOR_FILE
        }
      }
      await $dexie.dropbox.files.update(savedFileId, {
        name: `${sanitizedNewFileName}.${extension}`,
        nameLower: `${sanitizedNewFileName}.${extension}`.toLowerCase(),
        dateModified: new Date(),
        synced: 0,
        revision: ''
      })
      return {
        oldFileName,
        newFileName: sanitizedNewFileName,
        extension
      }
    } catch (err) {
      console.log(`Dexie.dropbox error! renameFromEditor err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
  }
}

export async function renameFromFilemanager($dexie, id, itemName, itemNewName, isFolder, itemExtension) {
  if (itemName !== itemNewName) {
    const dexieFile = await $dexie.dropbox.files.get(id)
    if (!dexieFile) {
      return {
        error: GeneralHelper.ErrorTypes.FILEMANAGER_RENAME
      }
    }
    const suffix = isFolder ? '' : `.${itemExtension}`
    let sanitizedItemNewName = GeneralHelper.sanitizedName(itemNewName, !isFolder)
    sanitizedItemNewName = await checkDuplicates($dexie, dexieFile.linkedAccountId, dexieFile.parentId, sanitizedItemNewName, suffix)
    try {
      await $dexie.dropbox.files.update(id, {
        name: `${sanitizedItemNewName}${suffix}`,
        nameLower: `${sanitizedItemNewName}${suffix}`.toLowerCase(),
        dateModified: isFolder ? new Date(0) : new Date(),
        synced: 0,
        revision: ''
      })
    } catch (err) {
      console.log(`Dexie.dropbox error! renameFromFilemanager err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.FILEMANAGER_RENAME
      }
    }
    return {
      itemNewName: sanitizedItemNewName,
      suffix
    }
  }
}

export async function newFolder($dexie, linkedAccountId, parentId, newFolderName) {
  let sanitizedNewFolderName = GeneralHelper.sanitizedName(newFolderName, false)
  sanitizedNewFolderName = await checkDuplicates($dexie, linkedAccountId, parentId, sanitizedNewFolderName, '')
  try {
    const id = await $dexie.dropbox.files.add({
      linkedAccountId,
      dropboxId: '',
      parentId,
      name: sanitizedNewFolderName,
      nameLower: sanitizedNewFolderName.toLowerCase(),
      dateModified: new Date(0),
      revision: '',
      synced: 0,
      kind: 'folder',
      content: ''
    })
    return {
      name: sanitizedNewFolderName,
      id
    }
  } catch (err) {
    console.log(`Dexie.dropbox error! newFolder err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_CREATE_FOLDER
    }
  }
}

export async function moveToTrash($dexie, id, addToTrash) {
  let filesToTrash = []
  try {
    filesToTrash = await $dexie.dropbox.files.where({parentId: id}).toArray()
  } catch (err) {
    console.log(err)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_DELETE_PERMANENT_MULTIPLE
    }
  }

  for (const fileToTrash of filesToTrash) {
    await moveToTrash($dexie, fileToTrash.id, false)
  }

  try {
    await $dexie.dropbox.transaction('rw', $dexie.dropbox.files, $dexie.dropbox.trashes, async () => {
      const dexieFile = await $dexie.dropbox.files.get(id)
      const pathLower = await getFullPathFromId($dexie, dexieFile.linkedAccountId, dexieFile.id)
      await $dexie.dropbox.files.delete(id)
      console.log(`id ${dexieFile.id} has been deleted`)
      if (dexieFile && dexieFile.dropboxId && addToTrash) {
        console.log(`Dropbox id ${dexieFile.dropboxId} of ${dexieFile.linkedAccountId} has been added to trash`)
        const dropboxTrashId = await $dexie.dropbox.trashes.add({
          linkedAccountId: dexieFile.linkedAccountId,
          dropboxId: dexieFile.dropboxId,
          pathLower
        })
        console.log(`New dropbox trash id: ${dropboxTrashId}`)
      }
    })
  } catch (err) {
    console.log(err)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_DELETE_PERMANENT_SINGLE
    }
  }

  return {
    id
  }
}

export async function readCurrentFileStatus($dexie, id) {
  try {
    const file = await $dexie.dropbox.files.get(id)
    let type
    if (file.kind === 'folder') {
      type = 'folder'
    } else {
      switch (GeneralHelper.getExtensionOnly(file.name)) {
        case SupportedFileHelper.DocumentFileFormat.MARKDOWN:
          type = SupportedFileHelper.DocumentFileFormat.MARKDOWN
          break
        case SupportedFileHelper.DocumentFileFormat.FOUNTAIN:
          type = SupportedFileHelper.DocumentFileFormat.FOUNTAIN
          break
        case SupportedFileHelper.DocumentFileFormat.LATEX:
          type = SupportedFileHelper.DocumentFileFormat.LATEX
          break
        case SupportedFileHelper.DocumentFileFormat.TEXT:
        default:
          type = SupportedFileHelper.DocumentFileFormat.TEXT
          break
      }
    }
    return {
      id: file.id,
      type,
      name: file.name,
      lastModified: file.dateModified.getTime() !== 0 ? moment(file.dateModified).valueOf() : '-',
      synced: file.synced
    }
  } catch (err) {
    console.log(`Dexie.dropbox error! readCurrentFileStatus err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_MODIFYTABLE
    }
  }
}

export async function uploadUnsyncedItems($notification, $dexie, dbx, linkedAccountId) {
  const unsyncedFiles = await $dexie.dropbox.files.where({linkedAccountId, synced: 0}).toArray()
  console.log('Unsynced dropbox files: ', unsyncedFiles)
  for (const unsyncedFile of unsyncedFiles) {
    if (unsyncedFile.kind === 'folder') {
      await uploadFolder($dexie, dbx, unsyncedFile)
    } else if (unsyncedFile.kind === 'file') {
      await uploadFile($dexie, dbx, unsyncedFile)
    }
    $notification.postMessageToAll(new FileNotification('dropbox', linkedAccountId, unsyncedFile.parentId, unsyncedFile.id, NotificationAction.FILE_MODIFIED))
  }
}

export async function uploadFile($dexie, dbx, file) {
  let path = null
  try {
    path = await getFullPathFromId($dexie, file.linkedAccountId, file.id)
  } catch (err) {
    throw new SyncError('W-DROPBOX-0110a')
  }
  if (!path) {
    throw new SyncError('W-DROPBOX-0110b')
  }
  if (file.dropboxId) {
    // Move file if parent is different
    let originalPath
    let resp
    try {
      resp = await dbx.filesGetMetadata({ path: file.dropboxId })
    } catch (err) {
      throw new SyncError('W-DROPBOX-0111a')
    }
    if (resp && resp.result && resp.result.path_lower) {
      originalPath = resp.result.path_lower
    } else {
      throw new SyncError('W-DROPBOX-0111b')
    }
    if (originalPath !== path.toLowerCase()) {
      // Dropbox allows dropbox Id as path
      let resp2
      try {
        resp2 = await dbx.filesMoveV2({from_path: originalPath, to_path: path, autorename: true})
      } catch (err) {
        if (err && err.error && err.error['.tag'] === 'duplicated_or_nested_paths') {
          console.log('Dropbox: No need to move', err)
        } else {
          throw new SyncError('W-DROPBOX-0112a')
        }
      }
      if (resp2 && resp2.result && resp2.result.metadata) {
        const metadata = resp2.result.metadata
        console.log('Update dropbox folder done: ', metadata)
        try {
          await $dexie.dropbox.files.update(file.id, {
            name: metadata.name,
            nameLower: metadata.name.toLowerCase(),
            synced: 0,
            dateModified: metadata.server_modified ? new Date(metadata.server_modified) : new Date(0),
            revision: metadata.rev || ''
          })
        } catch (err) {
          console.log(err)
          throw new SyncError('W-DROPBOX-0113')
        }
      } else {
        throw new SyncError('W-DROPBOX-0112b')
      }
    }
  }
  let resp
  try {
    resp = await dbx.filesUpload({path, contents: file.content, mode: 'overwrite'})
  } catch (err) {
    throw new SyncError('W-DROPBOX-0115a')
  }
  if (resp && resp.result) {
    const result = resp.result
    console.log('Upload dropbox file done: ', result)
    try {
      await $dexie.dropbox.files.update(file.id, {
        dropboxId: result.id,
        synced: 1,
        dateModified: result.server_modified ? new Date(result.server_modified) : new Date(0),
        revision: result.rev || ''
      })
    } catch (err) {
      throw new SyncError('W-DROPBOX-0116')
    }
  } else {
    throw new SyncError('W-DROPBOX-0115b')
  }
}

export async function uploadFolder($dexie, dbx, file) {
  let path = null
  try {
    path = await getFullPathFromId($dexie, file.linkedAccountId, file.id)
  } catch (err) {
    throw new SyncError('W-DROPBOX-0100a')
  }
  if (!path) {
    throw new SyncError('W-DROPBOX-0100b')
  }
  if (file.dropboxId) {
    // Dropbox allows dropbox Id as path
    let resp2
    try {
      resp2 = await dbx.filesMoveV2({from_path: file.dropboxId, to_path: path, autorename: true})
    } catch (err) {
      if (err && err.error && err.error['.tag'] === 'duplicated_or_nested_paths') {
        console.log('Dropbox: No need to move', err)
      } else {
        throw new SyncError('W-DROPBOX-0101a')
      }
    }
    if (resp2 && resp2.result && resp2.result.metadata) {
      const metadata = resp2.result.metadata
      console.log('Update dropbox folder done: ', metadata)
      try {
        await $dexie.dropbox.files.update(file.id, {
          name: metadata.name,
          nameLower: metadata.name.toLowerCase(),
          synced: 1,
          dateModified: metadata.server_modified ? new Date(metadata.server_modified) : new Date(0),
          revision: metadata.rev || ''
        })
      } catch (err) {
        throw new SyncError('W-DROPBOX-0102')
      }
    } else {
      throw new SyncError('W-DROPBOX-0101b')
    }
  } else {
    let resp
    try {
      resp = await dbx.filesCreateFolderV2({path, autorename: true})
    } catch (err) {
      throw new SyncError('W-DROPBOX-0103a')
    }
    if (resp && resp.result && resp.result.metadata) {
      const metadata = resp.result.metadata
      console.log('Upload dropbox folder done: ', metadata)
      try {
        await $dexie.dropbox.files.update(file.id, {
          dropboxId: metadata.id,
          name: metadata.name,
          nameLower: metadata.name.toLowerCase(),
          synced: 1,
          dateModified: metadata.server_modified ? new Date(metadata.server_modified) : new Date(0),
          revision: metadata.rev || ''
        })
      } catch (err) {
        throw new SyncError('W-DROPBOX-0104')
      }
    } else {
      throw new SyncError('W-DROPBOX-0103b')
    }
  }
}

export async function uploadTrashes($dexie, dbx, linkedAccountId) {
  const trashes = await $dexie.dropbox.trashes.where({linkedAccountId}).toArray()
  console.log('Unsynced dropbox trashes: ', trashes)
  for (const trash of trashes) {
    if (trash.pathLower && trash.pathLower !== '/') {
      let resp
      try {
        resp = await dbx.filesDeleteV2({path: trash.pathLower})
      } catch (err) {
        throw new SyncError('W-DROPBOX-0120a')
      }
      if (resp && resp.result) {
        try {
          await $dexie.dropbox.trashes.where({id: trash.id}).delete()
        } catch (err) {
          throw new SyncError('W-DROPBOX-0121')
        }
      } else {
        throw new SyncError('W-DROPBOX-0120b')
      }
    } else {
      try {
        await $dexie.dropbox.trashes.where({id: trash.id}).delete()
      } catch (err) {
        throw new SyncError('W-DROPBOX-0122')
      }
    }
  }
}

async function fetchChanges($dexie, dbx, cursor) {
  let changes = []
  const resp = await dbx.filesListFolderContinue({cursor})
  if (resp && resp.result) {
    const result = resp.result
    changes = result.entries
    if (result.cursor) {
      cursor = result.cursor
      if (result.has_more) {
        const {newChanges, newCursor} = await fetchChanges($dexie, dbx, result.cursor)
        changes = changes.concat(newChanges)
        cursor = newCursor
      }
    }
  }
  return {changes, cursor}
}

export async function handleChanges($notification, $dexie, dbx, linkedAccountId) {
  let latestCursorProperty
  try {
    latestCursorProperty = await $dexie.dropbox.properties.get({linkedAccountId, key: 'latestCursor'})
  } catch (err) {
    throw new SyncError('W-DROPBOX-0130')
  }
  if (!latestCursorProperty) {
    try {
      await saveLatestCursor($dexie, dbx, linkedAccountId)
    } catch (err) {
      throw new SyncError('W-DROPBOX-0131')
    }
    try {
      latestCursorProperty = await $dexie.dropbox.properties.get({linkedAccountId, key: 'latestCursor'})
    } catch (err) {
      throw new SyncError('W-DROPBOX-0132')
    }
  }
  if (!latestCursorProperty || !latestCursorProperty.value) {
    throw new SyncError('W-DROPBOX-0133')
  }
  console.log('Dropbox last cursor: ', latestCursorProperty)
  let changes
  let cursor
  try {
    const changesResp = await fetchChanges($dexie, dbx, latestCursorProperty.value)
    changes = changesResp.changes
    cursor = changesResp.cursor
  } catch (err) {
    throw new SyncError('W-DROPBOX-0134')
  }
  console.log('Dropbox changes: ', changes, cursor)
  for (const change of changes) {
    console.log('Dropbox change: ', change)
    /* eslint-disable dot-notation */
    const pathLower = change['path_lower']
    const dropboxId = change.id
    let dexieFile
    if (dropboxId) {
      try {
        dexieFile = await $dexie.dropbox.files.get({dropboxId})
      } catch (err) {
        throw new SyncError('W-DROPBOX-0134')
      }
    }
    switch (change['.tag']) {
      case 'deleted':
        // NOTE: dropboxId is not available for 'delete' change
        let fileId
        try {
          fileId = await getFileIdByPathTokens($dexie, linkedAccountId, pathLower.split('/'))
        } catch (err) {
          throw new SyncError('W-DROPBOX-0135')
        }
        if (!fileId) {
          console.log('Dropbox file is not available locally')
          continue
        }
        try {
          dexieFile = await $dexie.dropbox.files.get(fileId)
        } catch (err) {
          throw new SyncError('W-DROPBOX-0136')
        }
        if (!dexieFile) {
          console.log('Dropbox file is not available locally')
          continue
        }
        console.log('Dropbox changes in deletion: ', fileId, dexieFile)
        try {
          await $dexie.dropbox.files.delete(fileId)
        } catch (err) {
          throw new SyncError('W-DROPBOX-0137')
        }
        $notification.postMessageToAll(new FileNotification('dropbox', linkedAccountId, dexieFile.parentId, fileId, NotificationAction.FILE_DELETED))
        $notification.postMessageToAll(new FileNotification('dropbox', linkedAccountId, fileId, null, NotificationAction.FILE_DELETED))
        break
      case 'folder':
        if (dexieFile) {
          try {
            await $dexie.dropbox.files.update(dexieFile.id, {
              name: change.name,
              nameLower: change.name.toLowerCase()
            })
          } catch (err) {
            throw new SyncError('W-DROPBOX-0138')
          }
          $notification.postMessageToAll(new FileNotification('dropbox', linkedAccountId, dexieFile.parentId, dexieFile.id, NotificationAction.FILE_MODIFIED))
          $notification.postMessageToAll(new FileNotification('dropbox', linkedAccountId, dexieFile.id, null, NotificationAction.FILE_MODIFIED))
        } else {
          const parentPathTokens = pathLower.split('/')
          if (!parentPathTokens.length) {
            continue
          }
          parentPathTokens.pop()
          let parentId
          try {
            parentId = await getFileIdByPathTokens($dexie, linkedAccountId, parentPathTokens)
          } catch (err) {
            throw new SyncError('W-DROPBOX-0139')
          }
          if (parentId === false) {
            console.log('Dropbox file is not available locally')
            continue
          }
          let newFileId
          try {
            newFileId = await $dexie.dropbox.files.add({
              linkedAccountId,
              dropboxId,
              parentId,
              name: change.name,
              nameLower: change.name.toLowerCase(),
              dateModified: change.server_modified ? new Date(change.server_modified) : new Date(0),
              revision: change.rev || '',
              synced: 1,
              kind: 'folder',
              content: ''
            })
          } catch (err) {
            throw new SyncError('W-DROPBOX-0140')
          }
          $notification.postMessageToAll(new FileNotification('dropbox', linkedAccountId, parentId, newFileId, NotificationAction.FILE_MODIFIED))
        }
        break
      case 'file':
        if (dexieFile) {
          // Existing file
          if (dexieFile.revision === change.rev) {
            console.log('Dropbox file is not already downloaded, skipping...')
            continue
          }
          let resp
          try {
            resp = await dbx.filesDownload({ path: pathLower })
          } catch (err) {
            throw new SyncError('W-DROPBOX-0141a')
          }
          if (resp && resp.result && resp.result.fileBlob) {
            let content = null
            try {
              content = await resp.result.fileBlob.text()
            } catch (err) {
              throw new SyncError('W-DROPBOX-0142a')
            }
            console.log(content)
            if (content === null) {
              throw new SyncError('W-DROPBOX-0142b')
            }
            try {
              await $dexie.dropbox.files.update(dexieFile.id, {
                name: change.name,
                nameLower: change.name.toLowerCase(),
                dateModified: change.server_modified ? new Date(change.server_modified) : new Date(0),
                revision: change.rev || '',
                synced: 1,
                content
              })
            } catch (err) {
              throw new SyncError('W-DROPBOX-0143')
            }
            $notification.postMessageToAll(new FileNotification('dropbox', linkedAccountId, dexieFile.parentId, dexieFile.id, NotificationAction.FILE_MODIFIED))
          } else {
            throw new SyncError('W-DROPBOX-0141b')
          }
        } else {
          // New file
          if (!['.txt', '.md', '.fountain', '.tex'].find(ext => change.name.endsWith(ext))) {
            console.log('New dropbox file skipping: ', change.name)
            continue
          }
          const parentPathTokens = pathLower.split('/')
          if (!parentPathTokens.length) {
            continue
          }
          parentPathTokens.pop()
          let parentId
          try {
            parentId = await getFileIdByPathTokens($dexie, linkedAccountId, parentPathTokens)
          } catch (err) {
            throw new SyncError('W-DROPBOX-0144')
          }
          if (parentId) {
            if (parentId !== -1) {
              const dexieParentFile = await getFolderFromId($dexie, parentId)
              if (!dexieParentFile || dexieParentFile.synced === -1) {
                console.log('Dropbox file is not available locally 2', dexieParentFile)
                continue
              }
            }
          } else {
            console.log('Dropbox file\'s parent is not available locally')
            continue
          }
          const newFileId = await $dexie.dropbox.files.add({
            linkedAccountId,
            dropboxId,
            parentId,
            name: change.name,
            nameLower: change.name.toLowerCase(),
            dateModified: change.server_modified ? new Date(change.server_modified) : new Date(0),
            revision: change.rev || '',
            synced: -1,
            kind: 'file',
            content: ''
          })
          $notification.postMessageToAll(new FileNotification('dropbox', linkedAccountId, parentId, newFileId, NotificationAction.FILE_MODIFIED))
        }
        break
    }
  }
  return cursor
}

export async function getAccessToken($axios, linkedAccountId) {
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/dropbox/refresh`, {
    linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    return res.data.accessToken
  }
  return null
}

export async function downloadDirectory($dexie, $axios, linkedAccountId, id) {
  id = id || -1
  let dexieParentFile
  if (id !== -1) {
    dexieParentFile = await $dexie.dropbox.files.get(id)
    console.log('Downloading directory: Parent path ', id, dexieParentFile)
    if (!dexieParentFile || dexieParentFile.synced !== -1) {
      return
    }
  } else {
    const exploredRootProperty = await $dexie.dropbox.properties.get({linkedAccountId, key: 'exploredRoot'})
    console.log('Downloading directory: Explored root:', exploredRootProperty)
    if (exploredRootProperty && exploredRootProperty.value) {
      return
    }
  }
  console.log('Downloading directory: ', id, linkedAccountId, dexieParentFile)
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/dropbox/refresh`, {
    linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    const accessToken = res.data.accessToken
    console.log('Access token: ', accessToken)

    const { Dropbox } = require('dropbox')
    const dbx = new Dropbox({ accessToken, fetch: window.fetch.bind(window) })
    let more = true
    let cursor = null
    let error = false
    let files = []
    do {
      const parentPath = await getFullPathFromId($dexie, linkedAccountId, id)
      let resp
      if (cursor) {
        resp = await dbx.filesListFolderContinue({cursor})
      } else {
        resp = await dbx.filesListFolder({path: parentPath})
      }
      if (resp && resp.result && resp.result.entries) {
        files = files.concat(resp.result.entries)
        more = resp.result.has_more
        cursor = resp.result.cursor
        console.log('Dropbox: More | Cursor | ', more, cursor)
      } else {
        more = false
        cursor = null
        error = true
      }
    } while (more && cursor)

    if (error) {
      return
    }

    console.log('Dropbox done: ', files)

    for (const file of files) {
      if (file.name.match(/\.(txt|fountain|md|tex)$/g) || file['.tag'] === 'folder') {
        const nameLower = getNameFromFullPath(file.path_lower)
        const dexieFile = await $dexie.dropbox.files.get({linkedAccountId, parentId: id, nameLower})
        console.log('Added file: Existed? ', dexieFile, id, nameLower)
        if (!dexieFile) {
          const newFileId = await $dexie.dropbox.files.add({
            linkedAccountId,
            dropboxId: file.id,
            parentId: id,
            name: file.name,
            nameLower,
            dateModified: file.server_modified ? new Date(file.server_modified) : new Date(0),
            revision: file.rev || '',
            synced: -1,
            kind: (file['.tag'] === 'folder') ? 'folder' : 'file',
            content: ''
          })
          console.log('Added file: ', newFileId)
        }
      }
    }
    if (dexieParentFile) {
      await $dexie.dropbox.files.update(dexieParentFile.id, {
        synced: 1
      })
    } else {
      await $dexie.dropbox.properties.put({linkedAccountId, key: 'exploredRoot', value: true})
      await saveLatestCursor($dexie, dbx, linkedAccountId)
    }
  }
}

export async function downloadFile($dexie, $axios, id) {
  const file = await $dexie.dropbox.files.get({id})
  if (!file || !file.dropboxId || !file.linkedAccountId) {
    throw new Error('File not found')
  }
  const fullPath = await getFullPathFromId($dexie, file.linkedAccountId, id)
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/dropbox/refresh`, {
    linkedAccountId: file.linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    const accessToken = res.data.accessToken
    console.log('Access token: ', accessToken)
    const { Dropbox } = require('dropbox')
    const dbx = new Dropbox({ accessToken, fetch: window.fetch.bind(window) })
    const resp = await dbx.filesDownload({ path: fullPath })
    if (resp && resp.result && resp.result.fileBlob) {
      const content = await resp.result.fileBlob.text()
      console.log(content)
      await $dexie.dropbox.files.update(id, {
        synced: 1,
        content
      })
    } else {
      throw new Error('File not downloaded')
    }
  }
}

export async function saveLatestCursor($dexie, dbx, linkedAccountId) {
  const resp = await dbx.filesListFolderGetLatestCursor({path: '', recursive: true, include_deleted: true})
  if (resp && resp.result && resp.result.cursor) {
    console.log('Latest Cursor: ', resp.result.cursor)
    await $dexie.dropbox.properties.put({linkedAccountId, key: 'latestCursor', value: resp.result.cursor})
  }
}

export async function saveCursor($dexie, linkedAccountId, cursor) {
  try {
    await $dexie.dropbox.properties.put({linkedAccountId, key: 'latestCursor', value: cursor})
  } catch (err) {
    throw new SyncError('W-DROPBOX-0150')
  }
}

export async function updateSyncStatus($dexie, linkedAccountId, errorCode) {
  await $dexie.dropbox.properties.put({linkedAccountId, key: 'lastSyncCode', value: errorCode || ''})
  await $dexie.dropbox.properties.put({linkedAccountId, key: 'lastSyncDate', value: new Date()})
}

export async function getSyncStatus($dexie, linkedAccountId) {
  const errorCode = await $dexie.dropbox.properties.get({linkedAccountId, key: 'lastSyncCode'})
  const date = await $dexie.dropbox.properties.get({linkedAccountId, key: 'lastSyncDate'})
  return {
    errorCode: (errorCode && errorCode.value) ? errorCode.value : '',
    date: (date && date.value) ? date.value : null
  }
}

export async function unlinkAccount($dexie, linkedAccountId) {
  if (linkedAccountId) {
    await $dexie.dropbox.files.where({linkedAccountId}).delete()
    await $dexie.dropbox.properties.where({linkedAccountId}).delete()
    await $dexie.dropbox.trashes.where({linkedAccountId}).delete()
  } else {
    await $dexie.dropbox.files.clear()
    await $dexie.dropbox.properties.clear()
    await $dexie.dropbox.trashes.clear()
  }
}