<template lang="pug">
  v-system-bar#smartBanner.pa-0(app v-bind="getTheme" height="70" v-if="store && !hadClosed")
    v-banner(app width="100%" height="70" :color="getBannerBackground")
      .d-flex
        v-btn.mt-4(icon @click="close")
          v-icon(small) mdi-close
        .d-flex.justify-space-between(style="width: 100%;")
          v-list-item.px-0.mx-0(dense style="max-width: 70%;")
            v-list-item-avatar(size="48" color="#13C4AC" :rounded="getIconRounded")
              v-img(src="/icon/jotterpad_icon.svg" height="28" width="28" contain)
            v-list-item-content
              v-list-item-title.py-0.my-0 JotterPad - Markdown, Fountain
              v-list-item-subtitle.py-0.my-0
                span.text-caption FREE - On the {{ storeName }}
                br
                span
                  v-icon(color="orange") mdi-star
                  v-icon(color="orange") mdi-star
                  v-icon(color="orange") mdi-star
                  v-icon(color="orange") mdi-star
                  v-icon(color="orange") mdi-star-half-full
          .pt-4
            v-btn(text color="#13C4AC" :href="url" target="_blank") View
    v-divider(dark)
</template>

<script>
import { isAndroid, isIOS } from 'mobile-device-detect'

export default {
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      store: isAndroid ? 'android' : (isIOS ? 'ios' : ''),
      hadClosed: process.client ? window.localStorage.getItem('jp_smartBanner_closed') : true
    }
  },
  computed: {
    url() {
      if (this.store === 'ios') {
        return 'https://apps.apple.com/us/app/id1520190857'
      } else if (this.store === 'android') {
        return 'https://play.google.com/store/apps/details?id=com.jotterpad.x'
      }
      return ''
    },
    storeName() {
      if (this.store === 'ios') {
        return 'App Store'
      } else if (this.store === 'android') {
        return 'Play Store'
      }
      return 'Store'
    },
    getIconRounded() {
      if (this.store === 'ios') {
        return 'lg'
      } else if (this.store === 'android') {
        return 'circle'
      }
      return 'circle'
    },
    getTheme() {
      return this.dark ? {dark: true} : {light: true}
    },
    getBannerBackground() {
      return this.dark ? 'black' : 'white'
    }
  },
  methods: {
    close() {
      window.localStorage.setItem('jp_smartBanner_closed', true)
      this.hadClosed = true
    }
  }
}
</script>

<style lang="scss">
#smartBanner {
  .v-banner__wrapper {
    padding: 0 !important;
  }
}
</style>