import firebase from '@firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'

import config from '~/config/firebase'

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}
export const GoogleProvider = new firebase.auth.GoogleAuthProvider()
GoogleProvider.setCustomParameters({prompt: 'select_account'})

export const AppleProvider = new firebase.auth.OAuthProvider('apple.com')

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const database = firebase.database()

if (process.env.NODE_ENV !== 'production') {
  storage.useEmulator('localhost', 9199)
  database.useEmulator('localhost', 9000)
  auth.useEmulator('http://localhost:9099')
}

// if (process.env.firestoreDomain.includes('localhost')) {
//   firestore.settings({
//     host: process.env.firestoreDomain,
//     ssl: false
//   })
// }

export default firebase
