let callback

function init() {
  const d = document
  const s = d.createElement('script')
  s.setAttribute('id', 'crisp-script')
  s.src = 'https://client.crisp.chat/l.js'
  s.async = 1
  s.onload = () => {
    if (callback) {
      callback()
    }
  }
  d.getElementsByTagName('head')[0].appendChild(s)
}

export default (context, inject) => {
  init()
  window.$crisp = []
  window.$crisp.push(['config', 'color:theme', 'grey'])
  window.CRISP_WEBSITE_ID = '076e9a09-04a3-4982-9544-5e0e5ec11f2a'

  inject('crisp', {
    reload() {
      const d = document
      const s = d.createElement('script')
      s.setAttribute('id', 'crisp-script2')
      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
      const prevS = d.getElementById('crisp-script')
      prevS.parentNode.removeChild(prevS)
      d.getElementById('crisp-script2').id = 'crisp-script'
    },
    callback(cb) {
      callback = cb
    },
    loadUser(user) {
      if (window.$crisp) {
        console.log(`Setting crisp info ${user.email}, ${user.displayName}, ${user.photoURL}`, user)
        if (user.email) {
          window.$crisp.push(['set', 'user:email', user.email])
        }
        if (user.displayName) {
          window.$crisp.push(['set', 'user:nickname', user.displayName])
        }
        if (user.photoURL) {
          window.$crisp.push(['set', 'user:avatar', user.photoURL])
        }
      }
    }
  })
}