import { NotificationAction, FileNotification, SyncNotification } from '../helper/NotificationHelper'
import { SyncError } from '../helper/Error'

import * as DriveIO from '@/helper/io/drive'
import * as DropboxIO from '@/helper/io/dropbox'
import * as OneDriveIO from '@/helper/io/onedrive'

const sysend = require('sysend')

const syncId = new Date().getTime()
let isSyncing = false
let gotResponse = false
let tokens = {}

export default (context, inject) => {
  const { store, $axios, $dexie, $notification } = context

  window.addEventListener('unload', function (event) {
    if (localStorage.getItem('sync') === syncId) {
      localStorage.setItem('sync', '')
    }
  })
  sysend.on('sync', function (message) {
    if (message) {
      if (message.ask) {
        gotResponse = true
        sysend.broadcast('sync', { answer: syncId })
      } else if (message.answer !== syncId) {
        gotResponse = true
      }
    }
  })
  sysend.broadcast('sync', { ask: true })
  setTimeout(() => {
    if (!gotResponse) {
      gotResponse = true
      localStorage.setItem('sync', '')
      console.log('Sync: Resetting sync id in local storage')
    }
  }, 1000)

  setInterval(() => {
    tokens = {}
  }, 30000)

  inject('sync', {
    syncId,
    isSyncing,
    async start(selectedLinkedAccountId) {
      if (isSyncing) {
        console.log(`Sync: Sync id ${syncId} is already syncing...`)
        return
      }

      // Ensure only 1 web worker each time has the control to sync.
      const storedSyncId = localStorage.getItem('sync')
      const hasControl = !storedSyncId
      console.log(`Sync: Who has the control? ${storedSyncId}`)
      console.log(`Sync: Sync id ${syncId} ${hasControl ? '_has_' : '_does not_ have'} the control.`)

      if (!hasControl) {
        return
      }

      localStorage.setItem('sync', syncId)
      isSyncing = true
      $notification.postMessageToAll(new SyncNotification(NotificationAction.SYNC_ALL_START, '', ''))

      await store.dispatch('fetchUserClouds')
      const userClouds = store.state.userClouds

      console.log('To sync user clouds: ', userClouds)

      for (const userCloud of userClouds) {
        if (selectedLinkedAccountId && userCloud.linkedAccountId !== selectedLinkedAccountId) {
          console.log('Quick sync, ignoring user cloud... ', userCloud)
          continue
        }
        console.log('Syncing user cloud... ', userCloud)
        $notification.postMessageToAll(new SyncNotification(NotificationAction.SYNC_START, userCloud.linkedAccountId, ''))
        let errorCode = ''
        const linkedAccountId = userCloud.linkedAccountId
        try {
          if (userCloud.src === 'drive') {
            // MARK: GOOGLE DRIVE
            const tokenHash = `drive-${linkedAccountId}`
            let accessToken = tokens[tokenHash]
            if (!accessToken) {
              accessToken = await DriveIO.getAccessToken($axios, linkedAccountId)
            }
            if (!accessToken) {
              throw new SyncError('W-DRIVE-0001')
            }
            tokens[tokenHash] = accessToken
            await DriveIO.uploadUnsyncedItems($notification, $dexie, linkedAccountId, accessToken)
            await DriveIO.uploadTrashes($dexie, linkedAccountId, accessToken)
            const pageToken = await DriveIO.handleChanges($notification, $dexie, linkedAccountId, accessToken)
            if (!pageToken) {
              await DriveIO.savePageToken($dexie, linkedAccountId, accessToken)
            }
          } else if (userCloud.src === 'dropbox') {
            // MARK: DROPBOX
            const tokenHash = `dropbox-${linkedAccountId}`
            let accessToken = tokens[tokenHash]
            if (!accessToken) {
              accessToken = await DropboxIO.getAccessToken($axios, linkedAccountId)
            }
            if (!accessToken) {
              throw new SyncError('W-DROPBOX-0001')
            }
            tokens[tokenHash] = accessToken
            const { Dropbox } = require('dropbox')
            const dbx = new Dropbox({ accessToken, fetch: window.fetch.bind(window) })
            await DropboxIO.uploadUnsyncedItems($notification, $dexie, dbx, linkedAccountId)
            await DropboxIO.uploadTrashes($dexie, dbx, linkedAccountId)
            const cursor = await DropboxIO.handleChanges($notification, $dexie, dbx, linkedAccountId)
            if (cursor) {
              console.log(`Dropbox saving cursor: ${cursor}`)
              await DropboxIO.saveCursor($dexie, linkedAccountId, cursor)
            }
          } else if (userCloud.src === 'onedrive') {
            // MARK: ONEDRIVE
            const tokenHash = `onedrive-${linkedAccountId}`
            let accessToken = tokens[tokenHash]
            if (!accessToken) {
              accessToken = await OneDriveIO.getAccessToken($axios, linkedAccountId)
            }
            if (!accessToken) {
              throw new SyncError('W-ONEDRIVE-0001')
            }
            tokens[tokenHash] = accessToken
            await OneDriveIO.uploadUnsyncedItems($notification, $dexie, linkedAccountId, accessToken)
            await OneDriveIO.uploadTrashes($dexie, linkedAccountId, accessToken)
            const deltaLink = await OneDriveIO.handleChanges($notification, $dexie, linkedAccountId, accessToken)
            if (!deltaLink) {
              await OneDriveIO.saveDeltaLink($dexie, linkedAccountId, accessToken)
            }
          }
        } catch (err) {
          console.log('Sync error:', err)
          if (err instanceof SyncError) {
            errorCode = err.message || ''
          }
        }
        if (userCloud.src === 'drive') {
          await DriveIO.updateSyncStatus($dexie, linkedAccountId, errorCode)
        } else if (userCloud.src === 'dropbox') {
          await DropboxIO.updateSyncStatus($dexie, linkedAccountId, errorCode)
        } else if (userCloud.src === 'onedrive') {
          await OneDriveIO.updateSyncStatus($dexie, linkedAccountId, errorCode)
        }
        $notification.postMessageToAll(new SyncNotification(NotificationAction.SYNC_END, userCloud.linkedAccountId, errorCode))
      }

      $notification.postMessageToAll(new SyncNotification(NotificationAction.SYNC_ALL_END, '', ''))
      isSyncing = false
      localStorage.setItem('sync', '')
    }
  })
}