function isAdminRoute(route) {
  if (route.matched.some(record => record.path.includes('/app/admin'))) {
    return true
  }
}

export default function({ store, redirect, route }) {
  const redirectToRoot =
    // Editor, Folder: Ensure asyncData is exec in client, not server
    (process.server && route.matched.some(record => record.path.includes('/app/editor') || record.path.includes('/app/folder'))) ||
    (route.path === '/app/profile' || route.path === '/app/profile/') ||
    (isAdminRoute(route) && !(store.state.user || store.state.userIdToken))
  if (redirectToRoot) {
    redirect('/')
  } else if (route.path === '/app/editor') {
    redirect('/app/editor/local?getstarted=true&new=markdown')
  } else if (route.path === '/app/folder') {
    redirect('/app/folder/')
  }
}