import * as NotificationHelper from '../helper/NotificationHelper'

export default (context, inject) => {
  let messageReceiver

  const sysend = require('sysend')
  sysend.on('notification', function(message) {
    if (message && messageReceiver) {
      messageReceiver(message)
    }
  })

  window.addEventListener('message', (event) => {
    if (event.origin === window.location.origin && typeof event.data === 'string') {
      console.log('Api v1 Post message ', event)
      try {
        const data = JSON.parse(event.data)
        if (data && data.callbackName && window[data.callbackName]) {
          window[data.callbackName](data)
        }
      } catch (err) {
        console.log(err)
      }
    }
  })

  function postMessage(data) {
    sysend.broadcast('notification', data)
  }

  function postMessageToAll(data) {
    sysend.broadcast('notification', data)
    if (data && messageReceiver) {
      messageReceiver(data)
    }
  }

  function setupReceiver(receiver) {
    messageReceiver = receiver
  }

  function clearReceiver() {
    messageReceiver = null
  }

  inject('notification', {
    postMessage,
    postMessageToAll,
    setupReceiver,
    clearReceiver
  })
}