const MAX_RECENT_ITEMS = 15

export function addRecentItem(newRecentItem) {
  const recentItems = window.localStorage.getItem('jp_recentItems')
  if (recentItems === null) {
    const arrRecentItems = [newRecentItem]
    window.localStorage.setItem('jp_recentItems', JSON.stringify(arrRecentItems))
  } else {
    let parsedRecentItems = JSON.parse(recentItems)
    parsedRecentItems = parsedRecentItems.filter(item => item.url !== newRecentItem.url)
    parsedRecentItems.unshift(newRecentItem)
    if (parsedRecentItems.length > MAX_RECENT_ITEMS) {
      parsedRecentItems.pop()
    }
    console.log('Recent items:', parsedRecentItems)
    window.localStorage.setItem('jp_recentItems', JSON.stringify(parsedRecentItems))
  }
}

export function renameRecentItem(oldUrl, newUrl, newTitle) {
  const recentItems = window.localStorage.getItem('jp_recentItems')
  if (recentItems !== null) {
    const parsedRecentItems = JSON.parse(recentItems)
    parsedRecentItems.map((item) => {
      if (item.url === oldUrl) {
        item.title = newTitle
        item.url = newUrl
      }
    })
    console.log('Recent items:', parsedRecentItems)
    window.localStorage.setItem('jp_recentItems', JSON.stringify(parsedRecentItems))
  }
}

export function getRecentItems() {
  const recentItems = window.localStorage.getItem('jp_recentItems')
  if (recentItems === null) {
    return []
  } else {
    const parsedRecentItems = JSON.parse(recentItems)
    const returnedRecentItems = []
    parsedRecentItems.map((item) => {
      returnedRecentItems.push(item)
      returnedRecentItems.push({divider: true})
    })
    returnedRecentItems.pop()
    return returnedRecentItems
  }
}

export function deleteRecentItem(url) {
  const recentItems = window.localStorage.getItem('jp_recentItems')
  if (recentItems !== null) {
    let parsedRecentItems = JSON.parse(recentItems)
    parsedRecentItems = parsedRecentItems.filter(item => item.url !== url)
    console.log('Recent items:', parsedRecentItems)
    window.localStorage.setItem('jp_recentItems', JSON.stringify(parsedRecentItems))
  }
}

export function deleteNonLocalRecentItems() {
  const recentItems = window.localStorage.getItem('jp_recentItems')
  if (recentItems !== null) {
    let parsedRecentItems = JSON.parse(recentItems)
    parsedRecentItems = parsedRecentItems.filter(item => item.url.startsWith('/app/editor/local'))
    console.log('Recent items:', parsedRecentItems)
    window.localStorage.setItem('jp_recentItems', JSON.stringify(parsedRecentItems))
  }
}