<template lang="pug">
  v-dialog(v-model="value" max-width="360px" persistent)
    v-card#card
      v-btn#close(@click="close" absolute icon)
        v-icon mdi-close
      v-card-title.headline {{ $t('signInDialog.signIn') }}
      v-card-text Please select an account to continue.
      v-card-text.pb-6.text-center
        v-btn.no-uppercase.mb-4(color="white" @click="$auth.signIn('google'); close();")
          v-avatar.mr-6(tile size=18 color="transparent")
            v-img(src="/offline/editor/jotterpad-web-sign-in-google.svg" contain height=18)
          .black--text {{ $t('signInDialog.signInGoogle') }}
        v-btn.no-uppercase.mb-4(color="black" @click="$auth.signIn('apple'); close();")
          v-avatar.mr-6(tile size=18 color="transparent")
            v-img(src="/offline/editor/jotterpad-web-sign-in-apple.svg" contain height=18)
          .white--text {{ $t('signInDialog.signInApple') }}
</template>

<script>
export default {
  data() {
    return {
      value: false
    }
  },
  methods: {
    open() {
      this.value = true
    },
    close() {
      this.value = false
    }
  }
}
</script>

<style lang="scss" scoped>
.headline {
  font-family: 'Ideal Sans A', 'Ideal Sans B', sans-serif !important;
  font-weight: bold !important;
}
#card {
  position: relative;
}
#close {
  top: 0px;
  right: 0px;
  height: 36px;
  width: 36px;
}
.no-uppercase {
  text-transform: none !important;
}
</style>