import { auth } from '@/services/firebase.js'

export default (context, inject) => {
  const { store, redirect, route, $axios, $sync, $crisp } = context

  inject('auth', {
    async signIn(provider) {
      try {
        let signInWith = ''
        if (provider === 'google') {
          signInWith = 'signInWithGoogle'
        } else if (provider === 'apple') {
          signInWith = 'signInWithApple'
        } else {
          throw new Error('unsupported_provider')
        }
        const success = await store.dispatch(signInWith)
        if (success && route.path.indexOf('/app') !== 0 && !route.path.includes('/pro')) {
          redirect('/app/editor/local?getstarted=true&new=markdown')
        }
      } catch (err) {
        console.log(err)
      }
    },
    signInRedirect() {
      store.dispatch('signInWithGoogleRedirect')
    },
    async signOut() {
      try {
        await store.dispatch('signOut')
      } catch (err) {
        console.log(err)
      }
    },
    async refreshIdTokenIfExpired() {
      if (store.state.userIdTokenStoredTime && auth.currentUser) {
        const currentTime = new Date()
        const differenceInTime = currentTime.getTime() - store.state.userIdTokenStoredTime.getTime()
        const refresh = differenceInTime > 30 * 60 * 1000
        console.log(`DifferenceInTime in ms: ${differenceInTime} vs ${30 * 60 * 1000} | refresh?: ${refresh}`)
        if (refresh) {
          console.log('The store id token is already more than 30 minutes, getting a new one...')
          let idToken
          try {
            idToken = await auth.currentUser.getIdToken(true)
          } catch (err) {
            console.log('Firebase: No ID token')
          }
          if (idToken) {
            console.log('New id token: ', idToken)
            store.commit('setUserIdToken', idToken)
            store.commit('setUserIdTokenStoredTime', new Date())
            $axios.setHeader('Authorization', `Bearer ${idToken}`)
          }
        }
      }
    }
  })

  $crisp.callback(() => {
    if (store.state.user) {
      $crisp.loadUser(store.state.user)
    }
  })

  setInterval(async () => {
    console.log('Refreshing user id token...')
    if (auth.currentUser) {
      let idToken
      try {
        idToken = await auth.currentUser.getIdToken(true)
      } catch (err) {
        console.log('Firebase: No ID token')
      }
      if (idToken) {
        store.commit('setUserIdToken', idToken)
        store.commit('setUserIdTokenStoredTime', new Date())
        $axios.setHeader('Authorization', `Bearer ${idToken}`)
        console.log('New id token: ', idToken)
      }
    }
  }, 30 * 60 * 1000)

  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let idToken
        try {
          idToken = await user.getIdToken(true)
        } catch (err) {
          console.log('Firebase: No ID token')
        }
        if (idToken) {
          console.log('Firebase Auth: ', user, idToken)
          store.commit('setUserIdToken', idToken)
          store.commit('setUserIdTokenStoredTime', new Date())
          $axios.setHeader('Authorization', `Bearer ${idToken}`)
          store.commit('setUser', user)
          store.dispatch('fetchUserClouds')
          store.dispatch('checkUserIsPaid')
          $sync.start()
          $crisp.loadUser(user)
        }
      }
      return resolve()
    })
  })
}