module.exports = {
  async get(accessToken, endpoint, json) {
    const url = new URL(`https://graph.microsoft.com/v1.0/me/${endpoint}`)
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${accessToken}` }
    })
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        if (json) {
          const data = await response.json()
          return data
        } else {
          const data = await response.text()
          return data
        }
      } else {
        throw response.status
      }
    }
    return null
  },
  async getFromLink(accessToken, link) {
    const url = new URL(link)
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        const data = await response.json()
        return data
      } else {
        throw response.status
      }
    }
    return null
  },
  async getFromGenericLink(link, json) {
    const url = new URL(link)
    const response = await fetch(url.toString(), { method: 'GET' })
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        if (json) {
          const data = await response.json()
          return data
        } else {
          const data = await response.text()
          return data
        }
      } else {
        throw response.status
      }
    }
    return null
  },
  async request(method, accessToken, endpoint, params, body, contentType) {
    const url = new URL(`https://graph.microsoft.com/v1.0/me/${endpoint}`)
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value)
    }
    let headers
    if (contentType) {
      headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': contentType
      }
    } else {
      headers = {
        'Authorization': `Bearer ${accessToken}`
      }
    }
    const response = await fetch(url.toString(), {
      method,
      headers,
      body
    })
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        if (method === 'DELETE') {
          return response
        } else {
          const data = await response.json()
          return data
        }
      } else {
        throw response.status
      }
    }
    return null
  },
  async getRootFolder(accessToken) {
    const data = await this.get(accessToken, 'drive/root', true)
    if (data) {
      console.log(data)
      return data
    }
    return null
  },
  async listFiles(accessToken, onedriveParentId, isRoot) {
    const endpoint = isRoot ? 'drive/root/children' : `drive/items/${onedriveParentId}/children`
    let files
    const data = await this.get(accessToken, endpoint, true)
    if (data) {
      console.log(data)
      files = data.value
      let nextLink = data['@odata.nextLink']
      while (nextLink) {
        const moreData = await this.listMoreFiles(accessToken, nextLink)
        nextLink = moreData['@odata.nextLink']
        files = files.concat(moreData.value)
      }
    }
    return files
  },
  async listMoreFiles(accessToken, nextLink) {
    const data = await this.getFromLink(accessToken, nextLink)
    console.log(data)
    return data
  },
  async downloadFile(accessToken, onedriveId) {
    const item = await this.get(accessToken, `drive/items/${onedriveId}?$select=@microsoft.graph.downloadUrl`, true)
    const url = item['@microsoft.graph.downloadUrl']
    let data
    if (url) {
      data = await this.getFromGenericLink(url, false)
    } else {
      data = await this.get(accessToken, `drive/items/${onedriveId}/content`, false)
    }
    return data
  },
  async uploadNewFile(accessToken, parentGoogleId, name, content) {
    const data = await this.request('PUT', accessToken, `drive/items/${parentGoogleId}:/${name}:/content`, {
      '@microsoft.graph.conflictBehavior': 'rename'
    }, content, 'text/plain')
    return data
  },
  async updateFileContent(accessToken, onedriveId, content) {
    console.log('Update file content: ', accessToken, onedriveId, content)
    const data = await this.request('PUT', accessToken, `drive/items/${onedriveId}/content`, {
      '@microsoft.graph.conflictBehavior': 'replace'
    }, content, 'text/plain')
    return data
  },
  async uploadNewFolder(accessToken, parentOneDriveId, name) {
    console.log('Upload new folder: ', accessToken, parentOneDriveId, name)
    const metadata = {
      name,
      folder: {},
      '@microsoft.graph.conflictBehavior': 'rename'
    }
    const data = await this.request('POST', accessToken, `drive/items/${parentOneDriveId}/children`, {}, JSON.stringify(metadata), 'application/json')
    return data
  },
  async updateFileMetadata(accessToken, onedriveId, metadata) {
    console.log('Update file metadata: ', accessToken, onedriveId, metadata)
    const data = await this.request('PATCH', accessToken, `drive/items/${onedriveId}`, {}, JSON.stringify(metadata), 'application/json')
    return data
  },
  async deleteFile(accessToken, onedriveId) {
    console.log('Delete file: ', accessToken, onedriveId)
    const data = await this.request('DELETE', accessToken, `drive/items/${onedriveId}`, {}, null, null)
    return data
  },
  async getLatestDeltaLink(accessToken) {
    const data = await this.get(accessToken, 'drive/root/delta?token=latest', true)
    console.log(data)
    if (data && data['@odata.deltaLink']) {
      return data['@odata.deltaLink']
    }
    return null
  },
  async listChanges(accessToken, deltaLink) {
    let nextLink = deltaLink
    let data
    let changes = []
    let newLatestDeltaLink = null
    do {
      data = await this.getFromLink(accessToken, nextLink)
      if (data) {
        console.log(data)
        nextLink = data['@odata.nextLink']
        changes = changes.concat(data.value)
      }
    }
    while (nextLink)

    if (data['@odata.deltaLink']) {
      newLatestDeltaLink = data['@odata.deltaLink']
    }
    return {changes, newLatestDeltaLink}
  }
}