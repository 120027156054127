<template lang="pug">
  v-row.d-flex.align-center(justify="center")
    v-col(cols="auto").text-center
      h1(v-if="error.statusCode === 404") {{ pageNotFound }}
      h1(v-else) {{ otherError }}
      .mt-4
        v-btn(href="/") Return to Home
</template>

<script>

export default {
  layout: 'external',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head () {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  },
  data () {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    }
  },
  mounted() {
    this.$vuetify.theme.dark = true
  }
}
</script>