export const Theme = {
  SYSTEM: 0,
  LIGHT: 1,
  DARK: 2
}

export function themeToString(theme) {
  if (theme === Theme.SYSTEM) {
    return 'auto'
  } else if (theme === Theme.LIGHT) {
    return 'light'
  } else if (theme === Theme.DARK) {
    return 'dark'
  } else {
    return 'unknown'
  }
}

export const ErrorTypes = {
  DEFAULT: -1,
  EDITOR_FILE: 1,
  EDITOR_FILEFORMAT: 2,
  EDITOR_FOLDER: 3,
  EDITOR_DIRECTORY: 4,
  EDITOR_RENAME: 5,
  EDITOR_CREATE_FILE: 6,
  FILEMANAGER_CREATE_FOLDER: 7,
  FILEMANAGER_READ_FOLDER: 8,
  FILEMANAGER_RENAME: 9,
  FILEMANAGER_DELETE: 10,
  FILEMANAGER_RESTORE: 11,
  FILEMANAGER_IMPORT: 12,
  FILEMANAGER_DELETE_PERMANENT_SINGLE: 13,
  FILEMANAGER_DELETE_PERMANENT_MULTIPLE: 14,
  FILEMANAGER_MODIFYTABLE: 15,
  FILEMANAGER_MOVE: 16,
  CLOUD_USERNOTFOUND: 17,
  CLOUD_LINK_ACC_FAIL: 18,
  PUBLISHING_SVC_ADD_FAIL: 19,
  PUBLISHING_POST_FAIL: 20,
  FETCHING_BLOGS_FAIL: 21,
  UNLINK_FAIL: 22,
  DRIVEUI_SAVE_FAIL: 23,
  DRIVEUI_CLOUD_USERNOTFOUND: 24
}

export const SuccessTypes = {
  DEFAULT: 0,
  PUBLISHING_SVC_ADD_SUCCESS: 1,
  PUBLISHING_POST_SUCCESS: 2,
  UNLINK_SUCCESS: 3,
  RESET_SUCCESS: 4
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) { return '0 Bytes' }
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
}

export function getFilenameOnly(filenameWithExt) {
  return filenameWithExt.substring(0, filenameWithExt.lastIndexOf('.'))
}

export function getExtensionOnly(filenameWithExt) {
  return filenameWithExt.split('.').pop()
}

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export function makeid(length) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function sanitizedName(filename, isFile) {
  const defaultEmpty = isFile ? 'Untitled document' : 'Untitled folder'
  const result = filename.replace(/[\\~#%&*{}/:<>?|"-]/g, '-').trim()
  return (result.length !== 0) ? result : defaultEmpty
}

export function convertToHex(color) {
  function padHex(str) {
    return str.length === 1 ? `0${str}` : str
  }

  if (color.startsWith('rgb')) {
    const rgb = color.split(',')
    const r = parseInt(rgb[0].substring(4)).toString(16) // skip rgb(
    const g = parseInt(rgb[1]).toString(16) // this is just g
    const b = parseInt(rgb[2]).toString(16) // parseInt scraps trailing )

    return `#${padHex(r)}${padHex(g)}${padHex(b)}`
  }

  return color
}
