export const DocumentType = {
  MARKDOWN: 0,
  TEXT: 1,
  FOUNTAIN: 2,
  LATEX: 3
}

export const DocumentFileFormat = {
  MARKDOWN: 'md',
  TEXT: 'txt',
  FOUNTAIN: 'fountain',
  LATEX: 'tex',
  ZIP: 'zip',
  BIB: 'bib'
}

export const DocumentFileFormats = [
  DocumentFileFormat.MARKDOWN,
  DocumentFileFormat.TEXT,
  DocumentFileFormat.FOUNTAIN,
  DocumentFileFormat.LATEX
]

export const DocumentTypeName = {
  MARKDOWN: 'markdown',
  TEXT: 'text',
  FOUNTAIN: 'fountain',
  LATEX: 'latex'
}

export const DocumentTypeNames = [
  DocumentTypeName.MARKDOWN,
  DocumentTypeName.TEXT,
  DocumentTypeName.FOUNTAIN,
  DocumentTypeName.LATEX
]

export const FormatType = {
  MARKDOWN: 'md',
  HTML: 'html',
  FOUNTAIN: 'fountain',
  FINALDRAFT: 'fdx',
  LATEX: 'latex',
  JSON: 'json',
  EPUB: 'epub',
  DOCX: 'docx',
  ZIP: 'zip'
}

export function getFileFormat(type) {
  switch (type) {
    case DocumentType.MARKDOWN:
      return DocumentFileFormat.MARKDOWN
    case DocumentType.TEXT:
      return DocumentFileFormat.TEXT
    case DocumentType.FOUNTAIN:
      return DocumentFileFormat.FOUNTAIN
    case DocumentType.LATEX:
      return DocumentFileFormat.LATEX
    default:
      return 'unknown'
  }
}

export function getFileFormatFromName(name) {
  switch (name) {
    case DocumentTypeName.MARKDOWN:
      return DocumentFileFormat.MARKDOWN
    case DocumentTypeName.TEXT:
      return DocumentFileFormat.TEXT
    case DocumentTypeName.FOUNTAIN:
      return DocumentFileFormat.FOUNTAIN
    case DocumentTypeName.LATEX:
      return DocumentFileFormat.LATEX
    default:
      return 'unknown'
  }
}

export function getDocumentType(extension) {
  switch (extension) {
    case DocumentFileFormat.MARKDOWN:
      return DocumentType.MARKDOWN
    case DocumentFileFormat.TEXT:
      return DocumentType.TEXT
    case DocumentFileFormat.FOUNTAIN:
      return DocumentType.FOUNTAIN
    case DocumentFileFormat.LATEX:
      return DocumentType.LATEX
    default:
      return -1
  }
}

export function getDocumentTypeNameFromExt(extension) {
  switch (extension) {
    case DocumentFileFormat.MARKDOWN:
      return DocumentTypeName.MARKDOWN
    case DocumentFileFormat.TEXT:
      return DocumentTypeName.TEXT
    case DocumentFileFormat.FOUNTAIN:
      return DocumentTypeName.FOUNTAIN
    case DocumentFileFormat.LATEX:
      return DocumentTypeName.LATEX
    default:
      return -1
  }
}

export function getDocumentTypeFromName(name) {
  switch (name) {
    case DocumentTypeName.MARKDOWN:
      return DocumentType.MARKDOWN
    case DocumentTypeName.TEXT:
      return DocumentType.TEXT
    case DocumentTypeName.FOUNTAIN:
      return DocumentType.FOUNTAIN
    case DocumentTypeName.LATEX:
      return DocumentType.LATEX
    default:
      return -1
  }
}

export function getEditorId(type) {
  switch (type) {
    case DocumentType.MARKDOWN:
    case DocumentType.TEXT:
      return 'editor-markdown'
    case DocumentType.FOUNTAIN:
      return 'editor-fountain'
    case DocumentType.LATEX:
      return 'editor-latex'
    default:
      return 'unknown'
  }
}

export function getEditorUrl(type) {
  switch (type) {
    case DocumentType.MARKDOWN:
    case DocumentType.TEXT:
      return '/jotterpod3/word/index.html?formatType=md'
    case DocumentType.FOUNTAIN:
      return '/jotterpod3/screenplay/index.html?formatType=fountain'
    case DocumentType.LATEX:
      return '/jotterpod3/latex/index.html?formatType=latex'
    default:
      return ''
  }
}
