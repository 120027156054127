import moment from 'moment'

import * as GeneralHelper from '../GeneralHelper.js'
import * as SupportedFileHelper from '../SupportedFileHelper.js'
import { NotificationAction, FileNotification } from '../NotificationHelper'
import { SyncError } from '../Error'

async function checkFileExists($dexie, linkedAccountId, parentId, name) {
  try {
    const count = await $dexie.onedrive.files.where({ parentId, linkedAccountId, nameLower: name.toLowerCase() }).count()
    return count > 0
  } catch (err) {
    console.log(`Dexie.onedrive error! checkFileExists err: ${err}`)
    return false
  }
}

export async function checkFolderExists($dexie, linkedAccountId, parentId) {
  try {
    const count = await $dexie.onedrive.files.where({ id: parentId, linkedAccountId, kind: 'folder' }).count()
    return count > 0
  } catch (err) {
    console.log(`Dexie.onedrive error! checkFolderExists err: ${err}`)
    return false
  }
}

async function checkDuplicates($dexie, linkedAccountId, parentId, newName, suffix) {
  let name = `${newName}${suffix}`
  let exist = await checkFileExists($dexie, linkedAccountId, parentId, name)
  let number = 1
  let nameWithouthSuffix = newName
  while (exist) {
    nameWithouthSuffix = `${newName}-${number++}`
    name = `${nameWithouthSuffix}${suffix}`
    exist = await checkFileExists($dexie, linkedAccountId, parentId, name)
  }
  return nameWithouthSuffix
}

export async function getFile($dexie, linkedAccountId, parentId, id) {
  try {
    const file = await $dexie.onedrive.files.get({ id, linkedAccountId, parentId })
    return (file && file.kind === 'file') ? file : false
  } catch (err) {
    console.log(`Dexie.onedrive error! getFile err: ${err}`)
    return false
  }
}

export async function getFileFromId($dexie, id) {
  try {
    const file = await $dexie.onedrive.files.get(id)
    return (file && file.kind === 'file') ? file : false
  } catch (err) {
    console.log(`Dexie.onedrive error! getFileFromId err: ${err}`)
    return false
  }
}

export async function saveFile($dexie, linkedAccountId, parentId, savedFileId, oldFileName, newFileName, documentType, content) {
  const extension = SupportedFileHelper.getFileFormat(documentType)
  let sanitizedNewFileName = GeneralHelper.sanitizedName(newFileName, true)
  if (oldFileName !== sanitizedNewFileName) {
    sanitizedNewFileName = await checkDuplicates($dexie, linkedAccountId, parentId, sanitizedNewFileName, `.${extension}`)
  }
  const newDate = new Date()
  if (savedFileId !== null) {
    console.log(`UPDATING ID: ${savedFileId}`)
    try {
      const file = await $dexie.onedrive.files.get(savedFileId)
      if (!file) {
        return {
          error: GeneralHelper.ErrorTypes.EDITOR_FILE
        }
      }
      await $dexie.onedrive.files.update(savedFileId, {
        name: `${sanitizedNewFileName}.${extension}`,
        nameLower: `${sanitizedNewFileName}.${extension}`.toLowerCase(),
        dateModified: newDate,
        synced: 0,
        content,
        eTag: ''
      })
    } catch (err) {
      console.log(`Dexie.onedrive error! saveFile err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
    return {
      savedFileId,
      oldFileName: sanitizedNewFileName,
      newFileName: sanitizedNewFileName,
      extension
    }
  } else {
    console.log(`ADDING ID: ${savedFileId}`)
    let id
    try {
      id = await $dexie.onedrive.files.add({
        linkedAccountId,
        onedriveId: '',
        name: `${sanitizedNewFileName}.${extension}`,
        nameLower: `${sanitizedNewFileName}.${extension}`.toLowerCase(),
        parentId,
        dateModified: newDate,
        eTag: '',
        synced: 0,
        kind: 'file',
        content
      })
      console.log(`CREATED ID: ${id}`)
    } catch (err) {
      console.log(`Dexie.onedrive error! renameFromEditor err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
    return {
      savedFileId: id,
      oldFileName: sanitizedNewFileName,
      newFileName: sanitizedNewFileName,
      extension
    }
  }
}

export async function renameFromEditor($dexie, linkedAccountId, parentId, savedFileId, oldFileName, newFileName, documentType) {
  const extension = SupportedFileHelper.getFileFormat(documentType)
  let sanitizedNewFileName = GeneralHelper.sanitizedName(newFileName, true)
  if (oldFileName !== sanitizedNewFileName) {
    sanitizedNewFileName = await checkDuplicates($dexie, linkedAccountId, parentId, sanitizedNewFileName, `.${extension}`)
  }
  if (savedFileId !== null) {
    try {
      const file = await $dexie.onedrive.files.get(savedFileId)
      if (!file) {
        return {
          error: GeneralHelper.ErrorTypes.EDITOR_FILE
        }
      }
      await $dexie.onedrive.files.update(savedFileId, {
        name: `${sanitizedNewFileName}.${extension}`,
        nameLower: `${sanitizedNewFileName}.${extension}`.toLowerCase(),
        dateModified: new Date(),
        synced: 0,
        eTag: ''
      })
      return {
        oldFileName,
        newFileName: sanitizedNewFileName,
        extension
      }
    } catch (err) {
      console.log(`Dexie.onedrive error! renameFromEditor err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
  }
}

export async function calculateBreadcrumbs($dexie, linkedAccountId, parentId) {
  const grandParentNameAndId = []
  let currentParentId = parentId
  let findParent = true

  if (currentParentId === -1) {
    return []
  }

  while (findParent) {
    try {
      const parentFile = await $dexie.onedrive.files.get(currentParentId)
      if (parentFile) {
        if (parentFile.parentId === -1) {
          findParent = false
        } else {
          const grandParentFile = await $dexie.onedrive.files.get(parentFile.parentId)
          const name = grandParentFile && grandParentFile.name ? grandParentFile.name : 'unknown'
          grandParentNameAndId.unshift({ name, id: grandParentFile.id })
          currentParentId = parentFile.parentId
        }
      } else {
        grandParentNameAndId.unshift({ name: '...', id: null })
        findParent = false
      }
    } catch (err) {
      console.log(`Dexie.onedrive error! calculateBreadcrumbs err: ${err}`)
    }
  }
  const parentFile = await $dexie.onedrive.files.get(parentId)
  const name = parentFile && parentFile.name ? parentFile.name : 'unknown'
  return [...grandParentNameAndId, { name, id: parentId }]
}

export async function readCurrentDirectoryFiles($dexie, linkedAccountId, parentId) {
  const items = []

  parentId = parseInt(parentId) || -1

  try {
    await $dexie.onedrive.files.where({ linkedAccountId, parentId }).each((file) => {
      let type
      if (file.kind === 'folder') {
        type = 'folder'
      } else {
        switch (GeneralHelper.getExtensionOnly(file.name)) {
          case SupportedFileHelper.DocumentFileFormat.MARKDOWN:
            type = SupportedFileHelper.DocumentFileFormat.MARKDOWN
            break
          case SupportedFileHelper.DocumentFileFormat.FOUNTAIN:
            type = SupportedFileHelper.DocumentFileFormat.FOUNTAIN
            break
          case SupportedFileHelper.DocumentFileFormat.LATEX:
            type = SupportedFileHelper.DocumentFileFormat.LATEX
            break
          case SupportedFileHelper.DocumentFileFormat.TEXT:
          default:
            type = SupportedFileHelper.DocumentFileFormat.TEXT
            break
        }
      }
      items.push({
        id: file.id,
        type,
        name: file.name,
        lastModified: moment(file.dateModified).valueOf(),
        synced: file.synced
      })
    })
  } catch (err) {
    console.log(`Dexie.onedrive error! readCurrentDirectoryFiles err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_READ_FOLDER
    }
  }
  return {
    tableItems: items
  }
}

export async function readCurrentFileStatus($dexie, id) {
  try {
    const file = await $dexie.onedrive.files.get(id)
    let type
    if (file.kind === 'folder') {
      type = 'folder'
    } else {
      switch (GeneralHelper.getExtensionOnly(file.name)) {
        case SupportedFileHelper.DocumentFileFormat.MARKDOWN:
          type = SupportedFileHelper.DocumentFileFormat.MARKDOWN
          break
        case SupportedFileHelper.DocumentFileFormat.FOUNTAIN:
          type = SupportedFileHelper.DocumentFileFormat.FOUNTAIN
          break
        case SupportedFileHelper.DocumentFileFormat.LATEX:
          type = SupportedFileHelper.DocumentFileFormat.LATEX
          break
        case SupportedFileHelper.DocumentFileFormat.TEXT:
        default:
          type = SupportedFileHelper.DocumentFileFormat.TEXT
          break
      }
    }
    return {
      id: file.id,
      type,
      name: file.name,
      lastModified: moment(file.dateModified).valueOf(),
      synced: file.synced
    }
  } catch (err) {
    console.log(`Dexie.onedrive error! readCurrentFileStatus err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_MODIFYTABLE
    }
  }
}

export async function renameFromFilemanager($dexie, id, itemName, itemNewName, isFolder, itemExtension) {
  if (itemName !== itemNewName) {
    const dexieFile = await $dexie.onedrive.files.get(id)
    if (!dexieFile) {
      return {
        error: GeneralHelper.ErrorTypes.FILEMANAGER_RENAME
      }
    }
    const suffix = isFolder ? '' : `.${itemExtension}`
    let sanitizedItemNewName = GeneralHelper.sanitizedName(itemNewName, !isFolder)
    sanitizedItemNewName = await checkDuplicates($dexie, dexieFile.linkedAccountId, dexieFile.parentId, sanitizedItemNewName, suffix)
    try {
      await $dexie.onedrive.files.update(id, {
        name: `${sanitizedItemNewName}${suffix}`,
        nameLower: `${sanitizedItemNewName}${suffix}`.toLowerCase(),
        dateModified: new Date(),
        synced: 0,
        eTag: ''
      })
    } catch (err) {
      console.log(`Dexie.onedrive error! renameFromFilemanager err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.FILEMANAGER_RENAME
      }
    }
    return {
      itemNewName: sanitizedItemNewName,
      suffix
    }
  }
}

export async function newFolder($dexie, linkedAccountId, parentId, newFolderName) {
  let sanitizedNewFolderName = GeneralHelper.sanitizedName(newFolderName, false)
  sanitizedNewFolderName = await checkDuplicates($dexie, linkedAccountId, parentId, sanitizedNewFolderName, '')
  try {
    const id = await $dexie.onedrive.files.add({
      linkedAccountId,
      onedriveId: '',
      parentId,
      name: sanitizedNewFolderName,
      nameLower: sanitizedNewFolderName.toLowerCase(),
      dateModified: new Date(),
      eTag: '',
      synced: 0,
      kind: 'folder',
      content: ''
    })
    return {
      name: sanitizedNewFolderName,
      id
    }
  } catch (err) {
    console.log(`Dexie.onedrive error! newFolder err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_CREATE_FOLDER
    }
  }
}

export async function moveToTrash($dexie, id, addToTrash) {
  let filesToTrash = []
  try {
    filesToTrash = await $dexie.onedrive.files.where({ parentId: id }).toArray()
  } catch (err) {
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_DELETE_PERMANENT_MULTIPLE
    }
  }

  for (const fileToTrash of filesToTrash) {
    await moveToTrash($dexie, fileToTrash.id, false)
  }

  try {
    await $dexie.onedrive.transaction('rw', $dexie.onedrive.files, $dexie.onedrive.trashes, async () => {
      const dexieFile = await $dexie.onedrive.files.get(id)
      await $dexie.onedrive.files.delete(id)
      console.log(`id ${id} has been deleted`)
      if (dexieFile && dexieFile.onedriveId && addToTrash) {
        console.log(`OneDrive id ${dexieFile.onedriveId} of ${dexieFile.linkedAccountId} has been added to trash`)
        const onedriveTrashId = await $dexie.onedrive.trashes.add({
          linkedAccountId: dexieFile.linkedAccountId,
          onedriveId: dexieFile.onedriveId
        })
        console.log(`New onedrive trash id: ${onedriveTrashId}`)
      }
    })
  } catch (err) {
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_DELETE_PERMANENT_SINGLE
    }
  }
  return {
    id
  }
}

export async function downloadDirectory($dexie, $axios, linkedAccountId, parentId) {
  parentId = parseInt(parentId) || -1
  let dexieParentFile
  let parentOneDriveId
  if (parentId !== -1) {
    dexieParentFile = await $dexie.onedrive.files.get(parentId)
    console.log('Downloading directory: Parent id ', parentId, dexieParentFile)
    if (!dexieParentFile || dexieParentFile.synced !== -1) {
      return
    }
    parentOneDriveId = dexieParentFile.onedriveId
  } else {
    const exploredRootProperty = await $dexie.onedrive.properties.get({ linkedAccountId, key: 'exploredRoot' })
    console.log('Downloading directory: Explored root:', exploredRootProperty)
    if (exploredRootProperty && exploredRootProperty.value) {
      return
    }
  }
  console.log('Downloading directory: ', parentOneDriveId, linkedAccountId, dexieParentFile)
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/onedrive/refresh`, {
    linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    const accessToken = res.data.accessToken
    console.log('Access token: ', accessToken)
    // Use fetch instead of axios. Use our own implementation of gapi to avoid conflict.
    const files = await require('~/helper/service/onedrive').listFiles(accessToken, parentOneDriveId, parentId === -1)
    console.log(files)
    for (const file of files) {
      if ((file.name.match(/\.(txt|fountain|md|tex)$/g) && file.file) || file.folder) {
        const dexieFile = await $dexie.onedrive.files.get({ linkedAccountId, onedriveId: file.id })
        console.log('Added file: Existed? ', dexieFile)
        if (!dexieFile) {
          const newFileId = await $dexie.onedrive.files.add({
            linkedAccountId,
            onedriveId: file.id,
            parentId,
            name: file.name,
            nameLower: file.name.toLowerCase(),
            dateModified: new Date(file.lastModifiedDateTime),
            eTag: file.eTag,
            synced: -1,
            kind: (file.folder) ? 'folder' : 'file',
            content: ''
          })
          console.log('Added file: ', newFileId)
        }
      }
    }
    if (dexieParentFile) {
      await $dexie.onedrive.files.update(dexieParentFile.id, {
        synced: 1
      })
    } else {
      await $dexie.onedrive.properties.put({ linkedAccountId, key: 'exploredRoot', value: true })
      await saveRootFolderOneDriveId($dexie, linkedAccountId, accessToken)
      await saveDeltaLink($dexie, linkedAccountId, accessToken)
    }
  }
}

export async function downloadFile($dexie, $axios, id) {
  const file = await $dexie.onedrive.files.get({ id })
  if (!file || !file.onedriveId || !file.linkedAccountId) {
    throw new Error('File not found')
  }
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/onedrive/refresh`, {
    linkedAccountId: file.linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    console.log('Access token: ', res.data.accessToken)
    const content = await require('~/helper/service/onedrive').downloadFile(res.data.accessToken, file.onedriveId)
    await $dexie.onedrive.files.update(id, {
      onedriveId: file.onedriveId,
      synced: 1,
      content
    })
  } else {
    throw new Error('File not download')
  }
}

export async function saveRootFolderOneDriveId($dexie, linkedAccountId, accessToken) {
  const rootFile = await require('~/helper/service/onedrive').getRootFolder(accessToken)
  if (rootFile) {
    console.log('Root file: ', rootFile)
    await $dexie.onedrive.properties.put({ linkedAccountId, key: 'rootFileOneDriveId', value: rootFile.id })
    return rootFile.id
  }
  return ''
}

export async function uploadUnsyncedItems($notification, $dexie, linkedAccountId, accessToken) {
  const unsyncedFiles = await $dexie.onedrive.files.where({ linkedAccountId, synced: 0 }).toArray()
  console.log('Unsynced onedrive files: ', unsyncedFiles)
  for (const unsyncedFile of unsyncedFiles) {
    if (unsyncedFile.kind === 'folder') {
      await uploadFolder($dexie, accessToken, unsyncedFile)
    } else if (unsyncedFile.kind === 'file') {
      await uploadFile($dexie, accessToken, unsyncedFile)
    }
    $notification.postMessageToAll(new FileNotification('onedrive', linkedAccountId, unsyncedFile.parentId, unsyncedFile.id, NotificationAction.FILE_MODIFIED))
  }
}

export async function uploadFile($dexie, accessToken, file) {
  let parentOneDriveId
  if (file.parentId === -1) {
    parentOneDriveId = 'root'
  } else {
    const parentFile = await $dexie.onedrive.files.get({ linkedAccountId: file.linkedAccountId, id: file.parentId })
    parentOneDriveId = parentFile.onedriveId
  }
  if (parentOneDriveId === null) {
    throw new SyncError('W-ONEDRIVE-0110')
  }
  let resp
  const onedriveService = require('~/helper/service/onedrive')
  if (file.onedriveId) {
    try {
      resp = await onedriveService.updateFileMetadata(accessToken, file.onedriveId, {
        name: file.name,
        parentReference: {
          id: parentOneDriveId
        },
        '@microsoft.graph.conflictBehavior': 'rename'
      })
      resp = await onedriveService.updateFileContent(accessToken, file.onedriveId, file.content)
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0111')
    }
  } else {
    try {
      resp = await onedriveService.uploadNewFile(accessToken, parentOneDriveId, file.name, file.content)
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0112')
    }
  }
  if (resp && resp.id) {
    console.log('Upload OneDrive file done: ', resp)
    try {
      await $dexie.onedrive.files.update(file.id, {
        onedriveId: resp.id,
        synced: 1,
        eTag: resp.eTag,
        dateModified: new Date(resp.lastModifiedDateTime)
      })
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0113')
    }
  } else {
    throw new SyncError('W-ONEDRIVE-0114')
  }
}

export async function uploadFolder($dexie, accessToken, file) {
  let parentOneDriveId
  if (file.parentId === -1) {
    const rootFileOneDriveId = await $dexie.onedrive.properties.get({ linkedAccountId: file.linkedAccountId, key: 'rootFileOneDriveId' })
    parentOneDriveId = rootFileOneDriveId.value
  } else {
    const parentFile = await $dexie.onedrive.files.get({ linkedAccountId: file.linkedAccountId, id: file.parentId })
    parentOneDriveId = parentFile.onedriveId
  }
  if (parentOneDriveId === null) {
    throw new SyncError('W-ONEDRIVE-0100')
  }
  let resp
  const onedriveService = require('~/helper/service/onedrive')
  if (file.onedriveId) {
    try {
      resp = await onedriveService.updateFileMetadata(accessToken, file.onedriveId, {
        name: file.name,
        parentReference: {
          id: parentOneDriveId
        },
        '@microsoft.graph.conflictBehavior': 'rename'
      })
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0101')
    }
  } else {
    try {
      resp = await onedriveService.uploadNewFolder(accessToken, parentOneDriveId, file.name)
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0102')
    }
  }
  if (resp && resp.id) {
    console.log('Upload OneDrive folder done: ', resp)
    try {
      await $dexie.onedrive.files.update(file.id, {
        onedriveId: resp.id,
        synced: 1,
        eTag: resp.eTag,
        dataModified: new Date(resp.lastModifiedDateTime)
      })
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0103')
    }
  } else {
    throw new SyncError('W-ONEDRIVE-0104')
  }
}

export async function uploadTrashes($dexie, linkedAccountId, accessToken) {
  const onedriveService = require('~/helper/service/onedrive')
  const trashes = await $dexie.onedrive.trashes.where({ linkedAccountId }).toArray()
  console.log('Unsynced onedrive trashes: ', trashes)
  for (const trash of trashes) {
    if (trash.onedriveId) {
      try {
        await onedriveService.deleteFile(accessToken, trash.onedriveId)
      } catch (err) {
        throw new SyncError('W-ONEDRIVE-0120')
      }
    }
    try {
      await $dexie.onedrive.trashes.where({ linkedAccountId, onedriveId: trash.onedriveId }).delete()
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0121')
    }
  }
}

export async function handleChanges($notification, $dexie, linkedAccountId, accessToken) {
  const onedriveService = require('~/helper/service/onedrive')
  let latestDeltaLinkProperty
  try {
    latestDeltaLinkProperty = await $dexie.onedrive.properties.get({ linkedAccountId, key: 'latestDeltaLink' })
  } catch (err) {
    throw new SyncError('W-ONEDRIVE-0130a')
  }
  if (!latestDeltaLinkProperty) {
    try {
      await saveDeltaLink($dexie, linkedAccountId, accessToken)
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0130b')
    }
    try {
      latestDeltaLinkProperty = await $dexie.onedrive.properties.get({ linkedAccountId, key: 'latestDeltaLink' })
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0130c')
    }
  }
  if (!latestDeltaLinkProperty || !latestDeltaLinkProperty.value) {
    throw new SyncError('W-ONEDRIVE-0130d')
  }
  console.log('OneDrive last delta link: ', latestDeltaLinkProperty)
  let changes
  let newLatestDeltaLink
  try {
    const changesResp = await onedriveService.listChanges(accessToken, latestDeltaLinkProperty.value)
    changes = changesResp.changes
    newLatestDeltaLink = changesResp.newLatestDeltaLink
  } catch (err) {
    throw new SyncError('W-ONEDRIVE-0131a')
  }
  if (!changes || !newLatestDeltaLink) {
    console.log('No latest delta link, skipping')
    throw new SyncError('W-ONEDRIVE-0131b')
  }
  console.log(changes, newLatestDeltaLink)
  for (const change of changes) {
    console.log('change', change)
    if (change.deleted && change.id) {
      try {
        const dexieFiles = await $dexie.onedrive.files.where({ linkedAccountId, onedriveId: change.id }).toArray()
        for (const dexieFile of dexieFiles) {
          await $dexie.onedrive.files.where({ id: dexieFile.id }).delete()
          $notification.postMessageToAll(new FileNotification('onedrive', linkedAccountId, dexieFile.parentId, dexieFile.id, NotificationAction.FILE_DELETED))
          $notification.postMessageToAll(new FileNotification('onedrive', linkedAccountId, dexieFile.id, null, NotificationAction.FILE_DELETED))
        }
      } catch (err) {
        throw new SyncError('W-ONEDRIVE-0132')
      }
    } else if (change.id && (change.folder || change.file)) {
      const onedriveId = change.id
      const parentOneDriveId = change.parentReference.id || null
      const rootFileOneDrive = await $dexie.onedrive.properties.get({ linkedAccountId, key: 'rootFileOneDriveId' })
      let rootFileOneDriveId = rootFileOneDrive ? rootFileOneDrive.value : ''
      if (!rootFileOneDriveId) {
        try {
          rootFileOneDriveId = await saveRootFolderOneDriveId($dexie, linkedAccountId, accessToken)
        } catch (err) {
          throw new SyncError('W-ONEDRIVE-0133a')
        }
      }
      if (!rootFileOneDriveId) {
        throw new SyncError('W-ONEDRIVE-0133b')
      }
      if (!parentOneDriveId) {
        console.log('No parent skipping')
        continue
      }
      const dexieFiles = await $dexie.onedrive.files.where({ linkedAccountId, onedriveId }).toArray()
      if (dexieFiles.length) {
        // Existing item
        for (const dexieFile of dexieFiles) {
          const eTag = dexieFiles.eTag
          if (eTag !== change.eTag) {
            let content = ''
            if (change.file) {
              try {
                content = await onedriveService.downloadFile(accessToken, onedriveId)
              } catch (err) {
                throw new SyncError('W-ONEDRIVE-0134')
              }
            }
            try {
              let parentId = null
              if (parentOneDriveId && parentOneDriveId !== rootFileOneDriveId) {
                const parentDexieFile = await $dexie.onedrive.files.get({ linkedAccountId, onedriveId: parentOneDriveId })
                if (parentDexieFile && parentDexieFile.id) {
                  parentId = parentDexieFile.id
                }
              } else {
                parentId = -1
              }
              if (parentId) {
                if (change.folder) {
                  // Existing folder
                  await $dexie.onedrive.files.update(dexieFile.id, {
                    name: change.name,
                    nameLower: change.name.toLowerCase(),
                    parentId,
                    dateModified: new Date(change.lastModifiedDateTime),
                    eTag: change.eTag
                  })
                } else {
                  // Existing file
                  await $dexie.onedrive.files.update(dexieFile.id, {
                    name: change.name,
                    nameLower: change.name.toLowerCase(),
                    parentId,
                    dateModified: new Date(change.lastModifiedDateTime),
                    eTag: change.eTag,
                    synced: 1,
                    content
                  })
                }
                $notification.postMessageToAll(new FileNotification('onedrive', linkedAccountId, parentId, dexieFile.id, NotificationAction.FILE_MODIFIED))
                $notification.postMessageToAll(new FileNotification('onedrive', linkedAccountId, dexieFile.id, null, NotificationAction.FILE_MODIFIED))
              } else {
                // Delete folder/file if moved to parent that is not available locally
                await $dexie.onedrive.files.where({ id: dexieFile.id }).delete()
                $notification.postMessageToAll(new FileNotification('onedrive', linkedAccountId, dexieFile.parentId, dexieFile.id, NotificationAction.FILE_DELETED))
                $notification.postMessageToAll(new FileNotification('onedrive', linkedAccountId, dexieFile.id, null, NotificationAction.FILE_DELETED))
              }
              console.log('OneDrive change file updated ', dexieFile.id)
            } catch (err) {
              throw new SyncError('W-ONEDRIVE-0135')
            }
          } else {
            console.log('OneDrive change file skipped')
          }
        }
      } else {
        // New file
        if (!(change.file && ['.txt', '.md', '.fountain', '.tex'].find(ext => change.name.endsWith(ext))) && !change.folder) {
          console.log('New file skipping: ', change.name)
          continue
        }
        console.log('New file: ', change.name)
        let isParentDownloaded = false
        let rootFileOneDriveId = ''
        const rootFileOneDrive = await $dexie.onedrive.properties.get({ linkedAccountId, key: 'rootFileOneDriveId' })
        rootFileOneDriveId = rootFileOneDrive ? rootFileOneDrive.value : ''
        try {
          const count = await $dexie.onedrive.files.where({ onedriveId: parentOneDriveId })
            .and(file => file.synced !== -1).count()
          isParentDownloaded = (count > 0) || (parentOneDriveId === rootFileOneDriveId)
        } catch (err) {
          throw new SyncError('W-ONEDRIVE-0136')
        }
        if (isParentDownloaded) {
          try {
            let parentId
            if (parentOneDriveId === '' || parentOneDriveId === rootFileOneDriveId) {
              parentId = -1
            } else {
              const parentFile = await $dexie.onedrive.files.get({ onedriveId: parentOneDriveId })
              parentId = parentFile.id
            }
            const id = await $dexie.onedrive.files.add({
              linkedAccountId,
              onedriveId,
              parentId,
              name: change.name,
              nameLower: change.name.toLowerCase(),
              dateModified: new Date(change.lastModifiedDateTime),
              eTag: change.eTag,
              synced: -1,
              kind: (change.folder) ? 'folder' : 'file',
              content: ''
            })
            $notification.postMessageToAll(new FileNotification('onedrive', linkedAccountId, parentId, id, NotificationAction.FILE_MODIFIED))
          } catch (err) {
            throw new SyncError('W-ONEDRIVE-0137')
          }
        } else {
          console.log('OneDrive: parent is not downloaded. skipping...', parentOneDriveId)
        }
      }
    }
  }
  if (newLatestDeltaLink) {
    console.log('New delta link: ', newLatestDeltaLink)
    try {
      await $dexie.onedrive.properties.put({ linkedAccountId, key: 'latestDeltaLink', value: newLatestDeltaLink })
    } catch (err) {
      throw new SyncError('W-ONEDRIVE-0138')
    }
  }
  return newLatestDeltaLink
}

export async function saveDeltaLink($dexie, linkedAccountId, accessToken) {
  const onedriveService = require('~/helper/service/onedrive')
  let deltaLink
  try {
    deltaLink = await onedriveService.getLatestDeltaLink(accessToken)
  } catch (err) {
    throw new SyncError('W-ONEDRIVE-0140a')
  }
  if (!deltaLink) {
    throw new SyncError('W-ONEDRIVE-0140b')
  }
  console.log('Latest delta link: ', deltaLink)
  try {
    await $dexie.onedrive.properties.put({ linkedAccountId, key: 'latestDeltaLink', value: deltaLink })
  } catch (err) {
    throw new SyncError('W-ONEDRIVE-0141')
  }
}

export async function unlinkAccount($dexie, linkedAccountId) {
  if (linkedAccountId) {
    await $dexie.onedrive.files.where({ linkedAccountId }).delete()
    await $dexie.onedrive.properties.where({ linkedAccountId }).delete()
    await $dexie.onedrive.trashes.where({ linkedAccountId }).delete()
  } else {
    await $dexie.onedrive.files.clear()
    await $dexie.onedrive.properties.clear()
    await $dexie.onedrive.trashes.clear()
  }
}

export async function getAccessToken($axios, linkedAccountId) {
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/onedrive/refresh`, {
    linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    return res.data.accessToken
  }
  return null
}

export async function updateSyncStatus($dexie, linkedAccountId, errorCode) {
  await $dexie.onedrive.properties.put({ linkedAccountId, key: 'lastSyncCode', value: errorCode || '' })
  await $dexie.onedrive.properties.put({ linkedAccountId, key: 'lastSyncDate', value: new Date() })
}

export async function getSyncStatus($dexie, linkedAccountId) {
  const errorCode = await $dexie.onedrive.properties.get({ linkedAccountId, key: 'lastSyncCode' })
  const date = await $dexie.onedrive.properties.get({ linkedAccountId, key: 'lastSyncDate' })
  return {
    errorCode: (errorCode && errorCode.value) ? errorCode.value : '',
    date: (date && date.value) ? date.value : null
  }
}