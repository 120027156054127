<template lang="pug">
  v-app.app
    AppBar(colored=true)
    v-main
      nuxt(keep-alive :keep-alive-props="{max: 2}" :key="templatesAliveKey")
      SignInDialog(ref="signInDialog")
    AppFooter
</template>

<script>
import AppBar from '../components/AppBar.vue'
import AppFooter from '../components/AppFooter.vue'
import SignInDialog from '../components/editor/SignInDialog.vue'
import * as GeneralHelper from '../helper/GeneralHelper.js'

export default {
  components: {
    AppBar,
    AppFooter,
    SignInDialog
  },
  data() {
    return {
      theme: GeneralHelper.Theme.SYSTEM,
      darkMediaQuery: process.client ? window.matchMedia('(prefers-color-scheme: dark)') : null,
      templatesAliveKey: new Date().toString()
    }
  },
  mounted() {
    const theme = window.localStorage.getItem('jp_theme')
    if (theme !== null) {
      this.theme = parseInt(theme)
    }
    setTimeout(() => {
      if (this.theme === GeneralHelper.Theme.SYSTEM) {
        this.$vuetify.theme.dark = this.darkMediaQuery.matches
      } else {
        this.$vuetify.theme.dark = (this.theme === GeneralHelper.Theme.DARK)
      }
    }, 0)
    if (this.darkMediaQuery) {
      try {
        this.darkMediaQuery.addEventListener('change', this.changeTheme)
      } catch (e1) {
        try {
          this.darkMediaQuery.addListener(this.changeTheme)
        } catch (e2) {
        }
      }
    }
    this.$nuxt.$on('resetTemplatesAliveKey', (val) => {
      this.templatesAliveKey = new Date().toString()
    })
    this.$nuxt.$on('openDialog', (val) => {
      if (val) {
        this.openDialog(val)
      }
    })
  },
  beforeDestroy() {
    if (this.darkMediaQuery) {
      try {
        // Chrome & Firefox
        this.darkMediaQuery.removeEventListener('change', this.changeTheme)
      } catch (e1) {
        try {
          // Safari
          this.darkMediaQuery.removeListener(this.changeTheme)
        } catch (e2) {
        }
      }
    }
  },
  methods: {
    changeTheme() {
      if (this.theme === GeneralHelper.Theme.SYSTEM) {
        this.$vuetify.theme.dark = window.matchMedia('(prefers-color-scheme: dark)').matches
      }
    },
    openDialog(dialogType) {
      if (dialogType === 'signIn') {
        this.$refs.signInDialog.open()
      }
    }
  }
}
</script>