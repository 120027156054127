// const axiosRetry = require('axios-retry')

export default (context, inject) => {
  const { $axios } = context
  $axios.setBaseURL(location.origin)
  // axiosRetry($axios, {
  //   retries: 3,
  //   retryDelay: (retryCount) => {
  //     console.log(`Axios retry attempt: ${retryCount}`)
  //     return retryCount * 2000
  //   },
  //   retryCondition: (error) => {
  //     return error.response.status >= 500 && error.response.status <= 599
  //   }
  // })
  return {}
}