<template lang="pug">
div
  v-app-bar.appBar(:clipped-left="clipped" :color="colored ? 'rgba(20, 60, 51, 0.85)' : 'rgba(0, 0, 0, 0.65)'" height="64px" fixed app flat dark)
    span
      a.logo(href="/")
        img(src="../assets/home/jotterpad_icon.svg" height="40px" style="vertical-align: middle;" alt="JotterPad")
        span.name.text--accent.hidden-sm-and-down JotterPad™
    v-toolbar-items.hidden-sm-and-down
      v-btn.text-none(:text="true" v-for="item in items" :key="item.id" :href="item.link" :target="item.external ? '_blank' : ''") {{item.title}}
    v-spacer
    v-btn.text-none(:outlined="true" color="accent" nuxt to="/app/editor/local?getstarted=true&new=markdown")
      template(v-if="isLoggedIn")
        | {{ $t('appBar.goToEditor') }}
      template(v-else)
        | Try Online Editor
    v-app-bar-nav-icon.hidden-md-and-up(@click.stop="drawer = !drawer")
  v-navigation-drawer.drawer(v-model="drawer" app right temporary=true)
    v-list
      v-list-item(v-for="item in items" :key="item.id" :href="item.link" :target="item.external ? '_blank' : ''")
        v-list-item-content
          v-list-item-title {{item.title}}
      v-list-item
        v-list-item-content
          v-btn.text-none(:outlined="true" left absolute nuxt to="/app/editor/local?getstarted=true&new=markdown")
            template(v-if="isLoggedIn")
              | {{ $t('appBar.goToEditor') }}
            template(v-else)
              | Try Online Editor
</template>

<script>
export default {
  props: {
    colored: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      clipped: false,
      drawer: false,
      items: [
        {
          id: 0,
          title: this.$t('appBar.templates'),
          link: '/app/templates',
          external: false
        },
        {
          id: 4,
          title: this.$t('appBar.plugins'),
          link: '/app/plugins',
          external: false
        },
        {
          id: 3,
          title: this.$t('appBar.pro'),
          link: '/pro',
          external: false
        },
        {
          id: 5,
          title: this.$t('appBar.forum'),
          link: 'https://forum.jotterpad.app',
          external: true
        },
        {
          id: 1,
          title: this.$t('appBar.blog'),
          link: 'https://blog.jotterpad.app',
          external: true
        }
      ]
    }
  },
  computed: {
    isLoggedIn() {
      return !!this.$store.state.user
    }
  },
  methods: {
    signIn() {
      if (this.$store.state.user) {
        this.$router.push('/app')
      } else {
        this.$auth.signIn('google')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  vertical-align: middle;
  text-decoration: none;
  .name {
    margin: 0 16px;
    font-family: 'Ideal Sans A', 'Ideal Sans B', sans-serif;
    font-weight: bold;
  }
}
.appBar {
  backdrop-filter: blur(12px);
  z-index: 99;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.drawer {
  z-index: 99;
}
</style>