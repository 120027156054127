import moment from 'moment'

import * as GeneralHelper from '../GeneralHelper.js'
import * as SupportedFileHelper from '../SupportedFileHelper.js'
import { NotificationAction, FileNotification } from '../NotificationHelper'
import { SyncError } from '../Error'

export async function checkFolderExists($dexie, linkedAccountId, parentId) {
  try {
    const count = await $dexie.drive.files.where({ id: parentId, linkedAccountId, kind: 'folder' }).count()
    return count > 0
  } catch (err) {
    console.log(`Dexie.drive error! checkFolderExists err: ${err}`)
    return false
  }
}

export async function getFile($dexie, linkedAccountId, parentId, id) {
  try {
    let file
    await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async function() {
      const parentCount = await $dexie.drive.fileFolderRels.where({ linkedAccountId, parentId, fileId: id }).count()
      if (parentCount > 0) {
        file = await $dexie.drive.files.get(id)
      }
    })
    return (file && file.kind === 'file') ? file : false
  } catch (err) {
    console.log(`Dexie.drive error! getFile err: ${err}`)
    return false
  }
}

export async function getFileFromId($dexie, id) {
  try {
    const file = await $dexie.drive.files.get(id)
    return (file && file.kind === 'file') ? file : false
  } catch (err) {
    console.log(`Dexie.drive error! getFileFromId err: ${err}`)
    return false
  }
}

export async function getFileFromDriveUI($axios, googleId, linkedAccountId) {
  try {
    const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/drive/refresh`, {
      linkedAccountId
    })
    if (res.data && res.data.accessToken) {
      console.log('Access token: ', res.data.accessToken)
      const fileData = await require('~/helper/service/drive').getFileData(res.data.accessToken, googleId)
      const content = await require('~/helper/service/drive').downloadFile(res.data.accessToken, googleId)
      console.log('FILE DATA', fileData)
      const file = {
        id: googleId,
        name: fileData.name,
        version: fileData.version,
        mimeType: fileData.mimeType,
        parents: fileData.parents,
        content
      }
      return file
    } else {
      throw new Error('File not downloaded')
    }
  } catch (e) {
    console.log(e)
    return false
  }
}

export async function getFolderFromId($dexie, id) {
  try {
    const file = await $dexie.drive.files.get(id)
    return (file && file.kind === 'folder') ? file : false
  } catch (err) {
    console.log(`Dexie.drive error! getFolderFromId err: ${err}`)
    return false
  }
}

export async function getParentId($dexie, linkedAccountId, id) {
  try {
    const fileFolderRel = await $dexie.drive.fileFolderRels
      .where({ fileId: id, linkedAccountId })
      .first((fileFolderRel) => {
        return fileFolderRel
      })
    return fileFolderRel ? fileFolderRel.parentId : false
  } catch (err) {
    console.log(`Dexie.drive error! getParentId err: ${err}`)
    return false
  }
}

export async function saveFile($dexie, linkedAccountId, parentId, savedFileId, newFileName, documentType, content) {
  const extension = SupportedFileHelper.getFileFormat(documentType)
  const sanitizedNewFileName = GeneralHelper.sanitizedName(newFileName, true)
  const newDate = new Date()
  if (savedFileId !== null) {
    console.log(`UPDATING ID: ${savedFileId}`)
    try {
      const file = await $dexie.drive.files.get(savedFileId)
      if (!file) {
        return {
          error: GeneralHelper.ErrorTypes.EDITOR_FILE
        }
      }
      await $dexie.drive.files.update(savedFileId, {
        name: `${sanitizedNewFileName}.${extension}`,
        dateModified: newDate,
        synced: 0,
        content,
        version: ''
      })
    } catch (err) {
      console.log(`Dexie.drive error! saveFile err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
    return {
      savedFileId,
      oldFileName: sanitizedNewFileName,
      newFileName: sanitizedNewFileName,
      extension
    }
  } else {
    console.log(`ADDING ID: ${savedFileId}`)
    let id
    try {
      await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async function() {
        id = await $dexie.drive.files.add({
          linkedAccountId,
          googleId: '',
          name: `${sanitizedNewFileName}.${extension}`,
          dateModified: newDate,
          version: '',
          synced: 0,
          kind: 'file',
          content
        })
        await $dexie.drive.fileFolderRels.add({
          fileId: id,
          parentId,
          linkedAccountId
        })
        console.log(`CREATED ID: ${id}`)
      })
    } catch (err) {
      console.log(`Dexie.drive error! renameFromEditor err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
    return {
      savedFileId: id,
      oldFileName: sanitizedNewFileName,
      newFileName: sanitizedNewFileName,
      extension
    }
  }
}

export async function renameFromEditor($dexie, savedFileId, oldFileName, newFileName, documentType) {
  const extension = SupportedFileHelper.getFileFormat(documentType)
  const sanitizedNewFileName = GeneralHelper.sanitizedName(newFileName, true)
  if (savedFileId !== null) {
    try {
      const file = await $dexie.drive.files.get(savedFileId)
      if (!file) {
        return {
          error: GeneralHelper.ErrorTypes.EDITOR_FILE
        }
      }
      await $dexie.drive.files.update(savedFileId, {
        name: `${sanitizedNewFileName}.${extension}`,
        dateModified: new Date(),
        synced: 0,
        version: ''
      })
      return {
        oldFileName,
        newFileName: sanitizedNewFileName,
        extension
      }
    } catch (err) {
      console.log(`Dexie.drive error! renameFromEditor err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.EDITOR_RENAME
      }
    }
  }
}

export async function calculateBreadcrumbs($dexie, linkedAccountId, parentId) {
  const grandParentNameAndId = []
  let currentParentId = parentId
  let findParent = true

  if (currentParentId === -1) {
    return []
  }

  while (findParent) {
    try {
      await $dexie.drive.fileFolderRels
        .where({ fileId: currentParentId, linkedAccountId })
        .first(async (file) => {
          if (file) {
            console.log(file)
            if (file.parentId === -1) {
              findParent = false
            } else {
              const result2 = await $dexie.drive.files.get(file.parentId)
              grandParentNameAndId.unshift({name: result2 && result2.name ? result2.name : 'unknown', id: file.parentId})
              currentParentId = file.parentId
            }
          } else {
            grandParentNameAndId.unshift({name: '...', id: null})
            findParent = false
          }
        })
    } catch (err) {
      console.log(`Dexie.drive error! calculateBreadcrumbs err: ${err}`)
    }
  }
  const result1 = await $dexie.drive.files.get(parentId)
  const parentIdName = result1 && result1.name ? result1.name : 'unknown'
  return [...grandParentNameAndId, {name: parentIdName, id: parentId}]
}

export async function newFolder($dexie, linkedAccountId, parentId, newFolderName) {
  const sanitizedNewFolderName = GeneralHelper.sanitizedName(newFolderName, false)
  let id
  try {
    await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async function() {
      id = await $dexie.drive.files.add({
        linkedAccountId,
        googleId: '',
        name: sanitizedNewFolderName,
        dateModified: new Date(),
        version: '',
        synced: 0,
        kind: 'folder',
        content: ''
      })
      await $dexie.drive.fileFolderRels.add({
        fileId: id,
        parentId,
        linkedAccountId
      })
    })
  } catch (err) {
    console.log(`Dexie.drive error! newFolder err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_CREATE_FOLDER
    }
  }
  return {
    name: sanitizedNewFolderName,
    id
  }
}

export async function readCurrentDirectoryFolders($dexie, linkedAccountId, parentId) {
  const items = []

  parentId = parseInt(parentId) || -1

  try {
    await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async function() {
      const fileFolderRels = await $dexie.drive.fileFolderRels.where({linkedAccountId, parentId}).toArray()
      for (const fileFolderRel of fileFolderRels) {
        await $dexie.drive.files
          .where({ id: fileFolderRel.fileId, linkedAccountId, kind: 'folder' })
          .each((file) => {
            items.push({
              id: file.id,
              type: 'folder',
              name: file.name,
              lastModified: moment(file.dateModified).valueOf(),
              synced: file.synced
            })
          })
      }
    })
  } catch (err) {
    console.log(`Dexie.drive error! readCurrentDirectoryFiles err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_READ_FOLDER
    }
  }
  return {
    tableItems: items
  }
}

export async function readCurrentDirectoryFiles($dexie, linkedAccountId, parentId) {
  const items = []

  parentId = parseInt(parentId) || -1

  try {
    await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async function() {
      const fileFolderRels = await $dexie.drive.fileFolderRels.where({linkedAccountId, parentId}).toArray()
      await Promise.all(
        fileFolderRels.map(async (fileFolderRel) => {
          await $dexie.drive.files
            .where({id: fileFolderRel.fileId})
            .each((file) => {
              let type
              if (file.kind === 'folder') {
                type = 'folder'
              } else {
                switch (GeneralHelper.getExtensionOnly(file.name)) {
                  case SupportedFileHelper.DocumentFileFormat.MARKDOWN:
                    type = SupportedFileHelper.DocumentFileFormat.MARKDOWN
                    break
                  case SupportedFileHelper.DocumentFileFormat.FOUNTAIN:
                    type = SupportedFileHelper.DocumentFileFormat.FOUNTAIN
                    break
                  case SupportedFileHelper.DocumentFileFormat.LATEX:
                    type = SupportedFileHelper.DocumentFileFormat.LATEX
                    break
                  case SupportedFileHelper.DocumentFileFormat.TEXT:
                  default:
                    type = SupportedFileHelper.DocumentFileFormat.TEXT
                    break
                }
              }
              items.push({
                id: file.id,
                type,
                name: file.name,
                lastModified: moment(file.dateModified).valueOf(),
                synced: file.synced
              })
            })
        })
      )
    })
  } catch (err) {
    console.log(`Dexie.drive error! readCurrentDirectoryFiles err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_READ_FOLDER
    }
  }
  return {
    tableItems: items
  }
}

export async function readCurrentFileStatus($dexie, id) {
  try {
    const file = await $dexie.drive.files.get(id)
    let type
    if (file.kind === 'folder') {
      type = 'folder'
    } else {
      switch (GeneralHelper.getExtensionOnly(file.name)) {
        case SupportedFileHelper.DocumentFileFormat.MARKDOWN:
          type = SupportedFileHelper.DocumentFileFormat.MARKDOWN
          break
        case SupportedFileHelper.DocumentFileFormat.FOUNTAIN:
          type = SupportedFileHelper.DocumentFileFormat.FOUNTAIN
          break
        case SupportedFileHelper.DocumentFileFormat.LATEX:
          type = SupportedFileHelper.DocumentFileFormat.LATEX
          break
        case SupportedFileHelper.DocumentFileFormat.TEXT:
        default:
          type = SupportedFileHelper.DocumentFileFormat.TEXT
          break
      }
    }
    return {
      id: file.id,
      type,
      name: file.name,
      lastModified: moment(file.dateModified).valueOf(),
      synced: file.synced
    }
  } catch (err) {
    console.log(`Dexie.drive error! readCurrentFileStatus err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_MODIFYTABLE
    }
  }
}

export async function renameFromFilemanager($dexie, id, itemName, itemNewName, isFolder, itemExtension) {
  if (itemName !== itemNewName) {
    const suffix = isFolder ? '' : `.${itemExtension}`
    const sanitizedItemNewName = GeneralHelper.sanitizedName(itemNewName, !isFolder)
    try {
      await $dexie.drive.files.update(id, {
        name: `${sanitizedItemNewName}${suffix}`,
        dateModified: new Date(),
        synced: 0,
        version: ''
      })
    } catch (err) {
      console.log(`Dexie.drive error! renameFromFilemanager err: ${err}`)
      return {
        error: GeneralHelper.ErrorTypes.FILEMANAGER_RENAME
      }
    }
    return {
      itemNewName: sanitizedItemNewName,
      suffix
    }
  }
}

export async function moveFile($dexie, linkedAccountId, parentId, id, destinationParentId) {
  try {
    await $dexie.drive.transaction('rw', $dexie.drive.fileFolderRels, $dexie.drive.files, async () => {
      const fileFolderRels = await $dexie.drive.fileFolderRels.where({ fileId: id, parentId, linkedAccountId }).toArray()
      for (const fileFolderRel of fileFolderRels) {
        await $dexie.drive.fileFolderRels.update(fileFolderRel.id, {
          parentId: destinationParentId
        })
        // todo: loop this inner folders
        await $dexie.drive.files.update(fileFolderRel.fileId, {
          synced: 0,
          version: ''
        })
      }
    })
  } catch (err) {
    console.log(`Dexie.drive error! moveFile err: ${err}`)
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_MOVE
    }
  }
  return {
    oldParent: parentId,
    newParent: destinationParentId
  }
}

export async function moveToTrash($dexie, id, addToTrash) {
  const childrenIds = []
  try {
    await $dexie.drive.fileFolderRels
      .where({parentId: id})
      .each((fileFolderRel) => {
        childrenIds.push(fileFolderRel.fileId)
      })
  } catch (err) {
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_DELETE_PERMANENT_MULTIPLE
    }
  }

  for (const childrenId of childrenIds) {
    await moveToTrash($dexie, childrenId, false)
  }

  try {
    await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, $dexie.drive.trashes, async () => {
      const dexieFile = await $dexie.drive.files.get(id)
      await $dexie.drive.files.delete(id)
      console.log(`id ${id} has been deleted`)
      const fileFolderRels = await $dexie.drive.fileFolderRels.where({fileId: id}).toArray()
      for (const fileFolderRel of fileFolderRels) {
        await $dexie.drive.fileFolderRels.delete(fileFolderRel.id)
        console.log(`filefolderrel id ${fileFolderRel.id} with fileId ${id} has been deleted`)
      }
      if (dexieFile && dexieFile.googleId && addToTrash) {
        console.log(`Google id ${dexieFile.googleId} of ${dexieFile.linkedAccountId} has been added to trash`, $dexie.drive.trashes)
        const driveTrashId = await $dexie.drive.trashes.add({
          linkedAccountId: dexieFile.linkedAccountId,
          googleId: dexieFile.googleId
        })
        console.log(`New google trash id: ${driveTrashId}`)
      }
    })
  } catch (err) {
    return {
      error: GeneralHelper.ErrorTypes.FILEMANAGER_DELETE_PERMANENT_SINGLE
    }
  }
  return {
    id
  }
}

export async function downloadDirectory($dexie, $axios, linkedAccountId, parentId) {
  parentId = parseInt(parentId) || -1
  let dexieParentFile
  let parentGoogleId
  if (parentId !== -1) {
    dexieParentFile = await $dexie.drive.files.get(parentId)
    console.log('Downloading directory: Parent id ', parentId, dexieParentFile)
    if (!dexieParentFile || dexieParentFile.synced !== -1) {
      return
    }
    parentGoogleId = dexieParentFile.googleId
  } else {
    const exploredRootProperty = await $dexie.drive.properties.get({linkedAccountId, key: 'exploredRoot'})
    console.log('Downloading directory: Explored root:', exploredRootProperty)
    if (exploredRootProperty && exploredRootProperty.value) {
      return
    }
    parentGoogleId = ''
  }
  console.log('Downloading directory: ', parentGoogleId, linkedAccountId, dexieParentFile)
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/drive/refresh`, {
    linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    const accessToken = res.data.accessToken
    console.log('Access token: ', accessToken)
    // Use fetch instead of axios. Use our own implementation of gapi to avoid conflict.
    const files = await require('~/helper/service/drive').listFiles(accessToken, parentGoogleId)
    console.log(files)
    for (const file of files) {
      await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async () => {
        console.log('Added file: ', file.name, file)
        if (file.name.match(/\.(txt|fountain|md|tex)$/g) || file.mimeType === 'application/vnd.google-apps.folder') {
          const dexieFile = await $dexie.drive.files.get({linkedAccountId, googleId: file.id})
          console.log('Added file: Existed? ', dexieFile)
          if (!dexieFile) {
            const newFileId = await $dexie.drive.files.add({
              linkedAccountId,
              googleId: file.id,
              parentGoogleId,
              name: file.name,
              dateModified: new Date(file.modifiedTime),
              version: file.version,
              synced: -1,
              kind: (file.mimeType === 'application/vnd.google-apps.folder') ? 'folder' : 'file',
              content: ''
            })
            const newFileFolderRelId = await $dexie.drive.fileFolderRels.add({
              linkedAccountId,
              fileId: newFileId,
              parentId: dexieParentFile ? dexieParentFile.id : -1
            })
            console.log('Added file: ', newFileId, newFileFolderRelId)
          }
        }
      })
    }
    if (dexieParentFile) {
      await $dexie.drive.files.update(dexieParentFile.id, {
        synced: 1
      })
    } else {
      await $dexie.drive.properties.put({linkedAccountId, key: 'exploredRoot', value: true})
      await saveRootFolderGoogleId($dexie, linkedAccountId, accessToken)
      await savePageToken($dexie, linkedAccountId, accessToken)
    }
  }
}

export async function downloadFile($dexie, $axios, id) {
  const file = await $dexie.drive.files.get({id})
  if (!file || !file.googleId || !file.linkedAccountId) {
    throw new Error('File not found')
  }
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/drive/refresh`, {
    linkedAccountId: file.linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    console.log('Access token: ', res.data.accessToken)
    const content = await require('~/helper/service/drive').downloadFile(res.data.accessToken, file.googleId)
    await $dexie.drive.files.update(id, {
      googleId: file.googleId,
      synced: 1,
      content
    })
  } else {
    throw new Error('File not downloaded')
  }
}

async function getParentGoogleId($dexie, file) {
  const parentGoogleId = await $dexie.drive.fileFolderRels.where({linkedAccountId: file.linkedAccountId, fileId: file.id})
    .first(async (fileFolderRel) => {
      let parentGoogleId
      if (fileFolderRel.parentId === -1) {
        parentGoogleId = 'root'
      } else {
        const parentFile = await $dexie.drive.files.get({linkedAccountId: file.linkedAccountId, id: fileFolderRel.parentId})
        parentGoogleId = parentFile.googleId
      }
      return parentGoogleId
    })
  return parentGoogleId
}

export async function uploadUnsyncedItems($notification, $dexie, linkedAccountId, accessToken) {
  const unsyncedFiles = await $dexie.drive.files.where({linkedAccountId, synced: 0}).toArray()
  console.log('Unsynced google files: ', unsyncedFiles)
  for (const unsyncedFile of unsyncedFiles) {
    if (unsyncedFile.kind === 'folder') {
      await uploadFolder($dexie, accessToken, unsyncedFile)
    } else if (unsyncedFile.kind === 'file') {
      await uploadFile($dexie, accessToken, unsyncedFile)
    }
    const fileFolderRels = await $dexie.drive.fileFolderRels.where({linkedAccountId, fileId: unsyncedFile.id}).toArray()
    for (const fileFolderRel of fileFolderRels) {
      $notification.postMessageToAll(new FileNotification('drive', linkedAccountId, fileFolderRel.parentId, unsyncedFile.id, NotificationAction.FILE_MODIFIED))
    }
  }
}

export async function saveFileDirectToDrive($axios, linkedAccountId, fileId, parentId, fileName, docType, content) {
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/drive/refresh`, {
    linkedAccountId
  })
  if (!res.data || !res.data.accessToken) {
    throw new Error('Access Token Error')
  }
  const accessToken = res.data.accessToken
  let resp
  const driveService = require('~/helper/service/drive')
  const extension = SupportedFileHelper.getFileFormat(docType)
  const sanitizedNewFileName = GeneralHelper.sanitizedName(fileName, true)
  const newFileName = `${sanitizedNewFileName}.${extension}`
  if (fileId) {
    try {
      resp = await driveService.updateFile(accessToken, fileId, 'text/plain', newFileName, content)
      console.log(resp)
      return resp
    } catch (e) {
      return {
        error: GeneralHelper.ErrorTypes.DRIVEUI_SAVE_FAIL
      }
    }
  } else {
    try {
      resp = await driveService.uploadNewFile(accessToken, parentId, 'text/plain', newFileName, content)
      console.log(resp)
      return resp
    } catch (e) {
      return {
        error: GeneralHelper.ErrorTypes.DRIVEUI_SAVE_FAIL
      }
    }
  }
}

export async function uploadFile($dexie, accessToken, file) {
  let parentGoogleId
  try {
    parentGoogleId = await getParentGoogleId($dexie, file)
  } catch (err) {
    throw new SyncError('W-DRIVE-0110a')
  }
  if (parentGoogleId === null) {
    throw new SyncError('W-DRIVE-0110b')
  }
  let resp
  const driveService = require('~/helper/service/drive')
  if (file.googleId) {
    // TODO: Sync conflict management here
    try {
      resp = await driveService.updateFile(accessToken, file.googleId, 'text/plain', file.name, file.content)
    } catch (err) {
      throw new SyncError('W-DRIVE-0111')
    }
  } else {
    try {
      resp = await driveService.uploadNewFile(accessToken, parentGoogleId, 'text/plain', file.name, file.content)
    } catch (err) {
      throw new SyncError('W-DRIVE-0112')
    }
  }
  if (resp && resp.id) {
    console.log('Upload Google file done: ', resp)
    try {
      await $dexie.drive.files.update(file.id, {
        googleId: resp.id,
        synced: 1,
        version: resp.version,
        dateModified: new Date(resp.modifiedTime)
      })
    } catch (err) {
      throw new SyncError('W-DRIVE-0113')
    }
  } else {
    throw new SyncError('W-DRIVE-0114')
  }
}

export async function uploadFolder($dexie, accessToken, file) {
  let parentGoogleId
  try {
    parentGoogleId = await getParentGoogleId($dexie, file)
  } catch (err) {
    throw new SyncError('W-DRIVE-0100a')
  }
  if (parentGoogleId === null) {
    throw new SyncError('W-DRIVE-0100b')
  }
  let resp
  const driveService = require('~/helper/service/drive')
  if (file.googleId) {
    try {
      resp = await driveService.updateFileMetadata(accessToken, file.googleId, {name: file.name})
    } catch (err) {
      throw new SyncError('W-DRIVE-0101')
    }
  } else {
    try {
      resp = await driveService.uploadNewFolder(accessToken, parentGoogleId, file.name)
    } catch (err) {
      throw new SyncError('W-DRIVE-0102')
    }
  }
  if (resp && resp.id) {
    console.log('Upload Google folder done: ', resp)
    try {
      await $dexie.drive.files.update(file.id, {
        googleId: resp.id,
        synced: 1,
        version: resp.version,
        dateModified: new Date(resp.modifiedTime)
      })
    } catch (err) {
      throw new SyncError('W-DRIVE-0103')
    }
  } else {
    throw new SyncError('W-DRIVE-0104')
  }
}

export async function uploadTrashes($dexie, linkedAccountId, accessToken) {
  const driveService = require('~/helper/service/drive')
  const trashes = await $dexie.drive.trashes.where({linkedAccountId}).toArray()
  console.log('Unsynced google trashes: ', trashes)
  for (const trash of trashes) {
    if (trash.googleId) {
      try {
        await driveService.updateFileMetadata(accessToken, trash.googleId, {trashed: true})
      } catch (err) {
        console.log('Drive, trash', err)
        if (err === 404) {
          console.log('Drive, trash no longer in drive, skipping...')
        } else {
          throw new SyncError('W-DRIVE-0120')
        }
      }
    }
    try {
      await $dexie.drive.trashes.where({linkedAccountId, googleId: trash.googleId}).delete()
    } catch (err) {
      throw new SyncError('W-DRIVE-0121')
    }
  }
}

export async function handleChanges($notification, $dexie, linkedAccountId, accessToken) {
  const driveService = require('~/helper/service/drive')
  let startPageTokenProperty
  try {
    startPageTokenProperty = await $dexie.drive.properties.get({linkedAccountId, key: 'startPageToken'})
  } catch (err) {
    throw new SyncError('W-DRIVE-0130a')
  }
  if (!startPageTokenProperty) {
    try {
      await savePageToken($dexie, linkedAccountId, accessToken)
    } catch (err) {
      throw new SyncError('W-DRIVE-0130b')
    }
    try {
      startPageTokenProperty = await $dexie.drive.properties.get({linkedAccountId, key: 'startPageToken'})
    } catch (err) {
      throw new SyncError('W-DRIVE-0130c')
    }
  }
  if (!startPageTokenProperty || !startPageTokenProperty.value) {
    console.log('No start page token, skipping')
    throw new SyncError('W-DRIVE-0130d')
  }
  console.log('Last start page token: ', startPageTokenProperty)
  let changes
  let newStartPageToken
  try {
    const changesResp = await driveService.listChanges(accessToken, startPageTokenProperty.value)
    changes = changesResp.changes
    newStartPageToken = changesResp.newStartPageToken
  } catch (err) {
    throw new SyncError('W-DRIVE-0131a')
  }
  if (!changes || !newStartPageToken) {
    console.log('No start page token, skipping')
    throw new SyncError('W-DRIVE-0131b')
  }
  console.log(changes, newStartPageToken)
  for (const change of changes) {
    console.log('change', change)
    if ((change.removed && change.fileId) || (change.file && change.file.trashed)) {
      const messagesToPost = []
      try {
        await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async () => {
          const dexieFiles = await $dexie.drive.files.where({linkedAccountId, googleId: change.fileId}).toArray()
          for (const dexieFile of dexieFiles) {
            const fileFolderRels = await $dexie.drive.fileFolderRels.where({fileId: dexieFile.id}).toArray()
            await $dexie.drive.files.where({id: dexieFile.id}).delete()
            await $dexie.drive.fileFolderRels.where({fileId: dexieFile.id}).delete()
            for (const fileFolderRel of fileFolderRels) {
              messagesToPost.push(new FileNotification('drive', linkedAccountId, fileFolderRel.parentId, dexieFile.id, NotificationAction.FILE_DELETED))
              messagesToPost.push(new FileNotification('drive', linkedAccountId, dexieFile.id, null, NotificationAction.FILE_DELETED))
            }
          }
        })
      } catch (err) {
        throw new SyncError('W-DRIVE-0132')
      }
      for (const messageToPost of messagesToPost) {
        $notification.postMessageToAll(messageToPost)
      }
    } else if (change.file) {
      const googleId = change.file.id
      const parentGoogleIds = change.file.parents || []
      const rootFileGoogle = await $dexie.drive.properties.get({linkedAccountId, key: 'rootFileGoogleId'})
      let rootFileGoogleId = rootFileGoogle ? rootFileGoogle.value : ''
      if (!rootFileGoogleId) {
        try {
          rootFileGoogleId = await saveRootFolderGoogleId($dexie, linkedAccountId, accessToken)
        } catch (err) {
          throw new SyncError('W-DRIVE-0133a')
        }
      }
      if (!rootFileGoogleId) {
        throw new SyncError('W-DRIVE-0133b')
      }
      if (!parentGoogleIds.length) {
        console.log('No parents skipping')
        continue
      }
      const dexieFiles = await $dexie.drive.files.where({linkedAccountId, googleId}).toArray()
      if (dexieFiles.length) {
        // Existing item
        for (const dexieFile of dexieFiles) {
          const version = dexieFile.version
          if (version !== change.file.version) {
            let content = ''
            if (change.file.mimeType !== 'application/vnd.google-apps.folder') {
              try {
                content = await driveService.downloadFile(accessToken, googleId)
              } catch (err) {
                throw new SyncError('W-DRIVE-0134')
              }
            }
            const messagesToPost = []
            try {
              await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async () => {
                // Existing folder
                if (change.file.mimeType === 'application/vnd.google-apps.folder') {
                  await $dexie.drive.files.update(dexieFile.id, {
                    name: change.file.name,
                    dateModified: new Date(change.file.modifiedTime),
                    version: change.file.version
                  })
                } else {
                  // Existing file
                  await $dexie.drive.files.update(dexieFile.id, {
                    name: change.file.name,
                    dateModified: new Date(change.file.modifiedTime),
                    version: change.file.version,
                    synced: 1,
                    content
                  })
                }
                // Handle parent change when added remotely
                for (const parentGoogleId of parentGoogleIds) {
                  if (parentGoogleId && parentGoogleId !== rootFileGoogleId) {
                    const parentDexieFiles = await $dexie.drive.files.where({googleId: parentGoogleId}).toArray()
                    for (const parentDexieFile of parentDexieFiles) {
                      const count = await $dexie.drive.fileFolderRels.where({
                        linkedAccountId,
                        fileId: dexieFile.id,
                        parentId: parentDexieFile.id
                      }).count()
                      if (count === 0) {
                        await $dexie.drive.fileFolderRels.add({
                          linkedAccountId,
                          fileId: dexieFile.id,
                          parentId: parentDexieFile.id
                        })
                        messagesToPost.push(new FileNotification('drive', linkedAccountId, parentDexieFile.id, dexieFile.id, NotificationAction.FILE_MODIFIED))
                      }
                    }
                  } else {
                    const count = await $dexie.drive.fileFolderRels.where({
                      linkedAccountId,
                      fileId: dexieFile.id,
                      parentId: -1
                    }).count()
                    if (count === 0) {
                      await $dexie.drive.fileFolderRels.add({
                        linkedAccountId,
                        fileId: dexieFile.id,
                        parentId: -1
                      })
                      messagesToPost.push(new FileNotification('drive', linkedAccountId, -1, dexieFile.id, NotificationAction.FILE_MODIFIED))
                    }
                  }
                }

                // Handle parent change when removed remotely
                const currFileFolderRels = await $dexie.drive.fileFolderRels.where({fileId: dexieFile.id}).toArray()
                for (const currFileFolderRel of currFileFolderRels) {
                  let deleteFile = false
                  console.log('Deleting link: ', currFileFolderRel.parentId, parentGoogleIds)
                  if (currFileFolderRel.parentId === -1) {
                    console.log('del ', !parentGoogleIds.includes(rootFileGoogleId), rootFileGoogleId)
                    deleteFile = !parentGoogleIds.includes(rootFileGoogleId)
                  } else {
                    const parentDexieFile = await $dexie.drive.files.get({id: currFileFolderRel.parentId})
                    if (!parentDexieFile || !parentGoogleIds.includes(parentDexieFile.googleId)) {
                      deleteFile = true
                    }
                  }
                  if (deleteFile) {
                    await $dexie.drive.fileFolderRels.delete(currFileFolderRel.id)
                    messagesToPost.push(new FileNotification('drive', linkedAccountId, currFileFolderRel.parentId, dexieFile.id, NotificationAction.FILE_DELETED))
                  }
                }
              })
            } catch (err) {
              throw new SyncError('W-DRIVE-0135')
            }
            for (const messageToPost of messagesToPost) {
              $notification.postMessageToAll(messageToPost)
            }
            const fileFolderRels = await $dexie.drive.fileFolderRels.where({fileId: dexieFile.id}).toArray()
            for (const fileFolderRel of fileFolderRels) {
              $notification.postMessageToAll(new FileNotification('drive', linkedAccountId, fileFolderRel.parentId, dexieFile.id, NotificationAction.FILE_MODIFIED))
              $notification.postMessageToAll(new FileNotification('drive', linkedAccountId, dexieFile.id, null, NotificationAction.FILE_MODIFIED))
            }
            console.log('Drive change file updated ', dexieFile.id)
          } else {
            console.log('Drive change file skipped')
          }
        }
      } else {
        // New file
        if (!['.txt', '.md', '.fountain', '.tex'].find(ext => change.file.name.endsWith(ext)) && change.file.mimeType !== 'application/vnd.google-apps.folder') {
          console.log('New file skipping: ', change.file.name)
          continue
        }
        console.log('New file: ', change.file.name)
        let isParentDownloaded = false
        let rootFileGoogleId = ''
        try {
          const rootFileGoogle = await $dexie.drive.properties.get({linkedAccountId, key: 'rootFileGoogleId'})
          rootFileGoogleId = rootFileGoogle ? rootFileGoogle.value : ''
        } catch (err) {
        }
        for (const parentGoogleId of parentGoogleIds) {
          try {
            const count = await $dexie.drive.files.where({googleId: parentGoogleId})
              .and(file => file.synced !== -1).count()
            isParentDownloaded = isParentDownloaded || (count > 0) || (parentGoogleId === rootFileGoogleId)
            if (isParentDownloaded) {
              break
            }
          } catch (err) {
            throw new SyncError('W-DRIVE-0136')
          }
        }
        if (isParentDownloaded) {
          const messagesToPost = []
          try {
            await $dexie.drive.transaction('rw', $dexie.drive.files, $dexie.drive.fileFolderRels, async () => {
              const id = await $dexie.drive.files.add({
                linkedAccountId,
                googleId,
                name: change.file.name,
                dateModified: new Date(change.file.modifiedTime),
                version: change.file.version,
                synced: -1,
                kind: (change.file.mimeType === 'application/vnd.google-apps.folder') ? 'folder' : 'file',
                content: ''
              })
              for (const parentGoogleId of parentGoogleIds) {
                if (parentGoogleId === '' || parentGoogleId === rootFileGoogleId) {
                  await $dexie.drive.fileFolderRels.add({
                    fileId: id,
                    parentId: -1,
                    linkedAccountId
                  })
                  messagesToPost.push(new FileNotification('drive', linkedAccountId, -1, id, NotificationAction.FILE_MODIFIED))
                } else {
                  const parentFiles = await $dexie.drive.files.where({googleId: parentGoogleId}).toArray()
                  for (const parentFile of parentFiles) {
                    await $dexie.drive.fileFolderRels.add({
                      fileId: id,
                      parentId: parentFile.id,
                      linkedAccountId
                    })
                    messagesToPost.push(new FileNotification('drive', linkedAccountId, parentFile.id, id, NotificationAction.FILE_MODIFIED))
                  }
                }
              }
            })
          } catch (err) {
            throw new SyncError('W-DRIVE-0137')
          }
          for (const messageToPost of messagesToPost) {
            $notification.postMessageToAll(messageToPost)
          }
        } else {
          console.log('Drive: parent is not downloaded. skipping...', parentGoogleIds)
        }
      }
    }
  }
  if (newStartPageToken) {
    console.log('New Page token: ', newStartPageToken)
    try {
      await $dexie.drive.properties.put({linkedAccountId, key: 'startPageToken', value: newStartPageToken})
    } catch (err) {
      throw new SyncError('W-DRIVE-0138')
    }
  }
  return newStartPageToken
}

export async function saveRootFolderGoogleId($dexie, linkedAccountId, accessToken) {
  const rootFile = await require('~/helper/service/drive').getRootFolder(accessToken)
  if (rootFile) {
    console.log('Root file: ', rootFile)
    await $dexie.drive.properties.put({linkedAccountId, key: 'rootFileGoogleId', value: rootFile.id})
    return rootFile.id
  }
  return ''
}

export async function savePageToken($dexie, linkedAccountId, accessToken) {
  const driveService = require('~/helper/service/drive')
  let pageToken
  try {
    pageToken = await driveService.getStartPageToken(accessToken)
  } catch (err) {
    throw new SyncError('W-DRIVE-0140a')
  }
  if (!pageToken) {
    throw new SyncError('W-DRIVE-0140b')
  }
  console.log('First Page token: ', pageToken)
  try {
    await $dexie.drive.properties.put({linkedAccountId, key: 'startPageToken', value: pageToken})
  } catch (err) {
    throw new SyncError('W-DRIVE-0141')
  }
}

export async function unlinkAccount($dexie, linkedAccountId) {
  if (linkedAccountId) {
    await $dexie.drive.files.where({linkedAccountId}).delete()
    await $dexie.drive.fileFolderRels.where({linkedAccountId}).delete()
    await $dexie.drive.properties.where({linkedAccountId}).delete()
    await $dexie.drive.trashes.where({linkedAccountId}).delete()
  } else {
    await $dexie.drive.files.clear()
    await $dexie.drive.fileFolderRels.clear()
    await $dexie.drive.properties.clear()
    await $dexie.drive.trashes.clear()
  }
}

export async function getAccessToken($axios, linkedAccountId) {
  const res = await $axios.post(`${process.env.functionsBaseUrl}/oauth/drive/refresh`, {
    linkedAccountId
  })
  if (res.data && res.data.accessToken) {
    return res.data.accessToken
  }
  return null
}

export async function updateSyncStatus($dexie, linkedAccountId, errorCode) {
  await $dexie.drive.properties.put({linkedAccountId, key: 'lastSyncCode', value: errorCode || ''})
  await $dexie.drive.properties.put({linkedAccountId, key: 'lastSyncDate', value: new Date()})
}

export async function getSyncStatus($dexie, linkedAccountId) {
  const errorCode = await $dexie.drive.properties.get({linkedAccountId, key: 'lastSyncCode'})
  const date = await $dexie.drive.properties.get({linkedAccountId, key: 'lastSyncDate'})
  return {
    errorCode: (errorCode && errorCode.value) ? errorCode.value : '',
    date: (date && date.value) ? date.value : null
  }
}