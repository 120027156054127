const Cookies = require('js-cookie')

// LEVEL = 0: ALL, 1: BASIC, 2: MARKETING

export const get = (key) => {
  return Cookies.get(key)
}

export const set = (key, val, opts, level) => {
  if ((Cookies.get('level') ?? 2) >= level) {
    try {
      Cookies.set(key, val, opts ?? {})
    } catch (err) {
    }
  }
}

export const getLevel = () => {
  const levelInt = parseInt(Cookies.get('level'))
  if (levelInt || levelInt === 0) {
    return levelInt
  } else {
    return 2
  }
}

export const setLevel = (level) => {
  const levelInt = parseInt(level)
  if (levelInt || levelInt === 0) {
    try {
      Cookies.set('level', levelInt)
    } catch (err) {
    }
  }
}